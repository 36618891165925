define("@weareevermore/shuttle/templates/components/shuttle-type-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8mCJqYL/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\",true],[11,\"d\",[29,[[22,\"path\"]]]],[8],[9],[0,\"\\n\"],[7,\"path\",true],[10,\"d\",\"M0 0h24v24H0z\"],[10,\"fill\",\"none\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-type-svg.hbs"
    }
  });

  _exports.default = _default;
});