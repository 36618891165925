define("@weareevermore/satellite/components/dropdown/mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ['dropdown-item', 'btn', 'btn-blank', 'btn-block'],
    click: function click(e) {
      e.stopPropagation();

      if (this.onClick) {
        this.onClick();
      }

      if (this.closedropdown) {
        this.closedropdown();
      }

      return false;
    }
  });

  _exports.default = _default;
});