define("@weareevermore/satellite/components/dropdown/separator", ["exports", "@weareevermore/satellite/templates/components/dropdown/separator"], function (_exports, _separator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _separator.default,
    classNames: ['dropdown-separator']
  });

  _exports.default = _default;
});