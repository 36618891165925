define("@weareevermore/satellite/utils/is-touch-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = 'ontouchstart' in window || (navigator.msMaxTouchPoints || navigator.maxTouchPoints) > 2;

  _exports.default = _default;
});