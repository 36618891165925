define("@weareevermore/shuttle/adapters/shuttle-membership", ["exports", "ember-data/adapters/json-api", "@weareevermore/shuttle/mixins/jb2-adapter"], function (_exports, _jsonApi, _jb2Adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_jb2Adapter.default);

  _exports.default = _default;
});