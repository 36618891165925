define("@weareevermore/satellite/templates/components/radio-buttons/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QWrljMQC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",false],[12,\"name\",[22,\"name\"]],[12,\"value\",[22,\"value\"]],[12,\"checked\",[22,\"isChecked\"]],[12,\"disabled\",[22,\"disabled\"]],[12,\"type\",\"radio\"],[3,\"action\",[[23,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[8],[9],[0,\"\\n\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"icon animate field-radio-icon \",[28,\"if\",[[24,[\"isChecked\"]],\"checked\"],null]]]],[8],[0,\"\\n  \"],[1,[28,\"if\",[[24,[\"isChecked\"]],\"radio_button_checked\",\"radio_button_unchecked\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"span\",true],[8],[1,[22,\"label\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/radio-buttons/item.hbs"
    }
  });

  _exports.default = _default;
});