define("@weareevermore/shuttle/services/shuttle-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    contactService: Ember.inject.service('shuttle-contact'),
    dialog: Ember.inject.service('dialog'),
    notify: Ember.inject.service('notify'),
    store: Ember.inject.service(),
    findRecord: function findRecord(id) {
      var include = 'tags,point-person,memberships,memberships.institution';
      return this.get('store').findRecord('shuttle-person', id, {
        include: include
      });
    },
    updateRecord: function updateRecord(person, attrs) {
      var _this = this;

      person.setProperties(attrs);
      return person.save().then(function () {
        _this.get('notify').success('Information was saved successfully.');
      });
    },
    updateContacts: function updateContacts(person, contacts) {
      var _this2 = this;

      return this.get('contactService').saveAll(contacts, {
        person: person
      }).then(function () {
        _this2.get('notify').success('Contacts were saved successfully.');
      });
    },
    createMembership: function createMembership(person, data) {
      var membership = this.get('store').createRecord('shuttle-membership', {
        person: person
      });
      return this._saveMembership(membership, data);
    },
    updateMembership: function updateMembership(membership, data) {
      return this._saveMembership(membership, data);
    },
    deleteMembership: function deleteMembership(membership) {
      var _this3 = this;

      return this.get('dialog').confirmDelete().then(function () {
        return membership.destroyRecord();
      }).then(function () {
        _this3.get('notify').success('Membership was deleted successfully.');
      });
    },
    createRelation: function createRelation(person, attrs) {
      var _this4 = this;

      var relation = this.get('store').createRecord('shuttle-relation', {
        person: person,
        type: attrs.type,
        relatedTo: attrs.relatedTo
      });
      return relation.save().then(function () {
        _this4.get('notify').success('Relation was created successfully.');
      });
    },
    deleteRelation: function deleteRelation(relation) {
      var _this5 = this;

      return this.get('dialog').confirmDelete().then(function () {
        return relation.destroyRecord();
      }).then(function () {
        _this5.get('notify').success('Relation was deleted successfully.');
      });
    },
    _saveMembership: function _saveMembership(membership, _ref) {
      var _this6 = this;

      var attrs = _ref.attrs,
          contacts = _ref.contacts;
      membership.setProperties(attrs);
      return membership.save().then(function (membership) {
        return _this6.get('contactService').saveAll(contacts, {
          membership: membership
        });
      }).then(function () {
        _this6.get('notify').success('Membership was saved successfully.');
      });
    }
  });

  _exports.default = _default;
});