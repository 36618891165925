define("@weareevermore/shuttle/components/shuttle-slot/header", ["exports", "@weareevermore/shuttle/templates/components/shuttle-slot/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _header.default,
    classNameBindings: [':shuttle-slot-header', ':flex-row', ':flex-center', ':animate', 'isActive'],
    click: function click() {
      this.onClick(!this.get('isActive'));
    }
  });

  _exports.default = _default;
});