define("@weareevermore/satellite/templates/components/error-page/generic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7bd7syA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"error-page-code\"],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"Oops!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[10,\"class\",\"error-page-message\"],[8],[0,\"\\n  Looks like something went wrong.\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/error-page/generic.hbs"
    }
  });

  _exports.default = _default;
});