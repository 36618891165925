define("@weareevermore/satellite/components/checkbox", ["exports", "@weareevermore/satellite/mixins/base-input", "@weareevermore/satellite/templates/components/checkbox"], function (_exports, _baseInput, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseInput.default, {
    layout: _checkbox.default,
    checked: Ember.computed.alias('value'),
    actions: {
      onChange: function onChange(e) {
        this.set('value', e.target.checked);
      }
    }
  });

  _exports.default = _default;
});