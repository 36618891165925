define("@weareevermore/satellite/components/loader", ["exports", "@weareevermore/satellite/templates/components/loader"], function (_exports, _loader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _loader.default,
    tagName: 'svg',
    classNames: ['loader'],
    attributeBindings: ['width', 'height', 'viewBox'],
    size: 24,
    stroke: 8,
    width: Ember.computed.reads('size'),
    height: Ember.computed.reads('size'),
    viewBox: Ember.computed('stroke', function () {
      var box = 60 + this.get('stroke');
      return "0 0 ".concat(box, " ").concat(box);
    }),
    coordinate: Ember.computed('stroke', function () {
      return 30 + this.get('stroke') / 2;
    })
  });

  _exports.default = _default;
});