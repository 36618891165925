define("@weareevermore/shuttle/templates/components/shuttle-institution/headquarter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ud5P6WMD",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"address1\"]],[[\"@label\",\"@value\"],[\"Address1\",[24,[\"headquarter\",\"address1\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"address2\"]],[[\"@label\",\"@value\"],[\"Address2\",[24,[\"headquarter\",\"address2\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"city\"]],[[\"@label\",\"@value\"],[\"City\",[24,[\"headquarter\",\"city\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"state\"]],[[\"@label\",\"@value\"],[\"State\",[24,[\"headquarter\",\"state\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"postal-code\"]],[[\"@label\",\"@value\"],[\"Postal Code\",[24,[\"headquarter\",\"postalCode\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"country\"]],[[\"@label\",\"@value\"],[\"Country\",[24,[\"headquarter\",\"country\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/contacts-field\",[],[[\"@label\",\"@value\"],[\"Location Contacts\",[24,[\"headquarter\",\"contacts\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-institution/headquarter.hbs"
    }
  });

  _exports.default = _default;
});