define("@weareevermore/satellite/constants/apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    name: 'User Management',
    key: 'users',
    link: 'https://auth.worldjewishcongress.org/users/',
    icon: '@weareevermore/satellite/images/apps/users.svg',
    accessLevels: ['none', 'partial', 'full']
  }, {
    name: 'Contacts',
    key: 'contacts',
    link: 'https://contacts.worldjewishcongress.org',
    icon: '@weareevermore/satellite/images/apps/contacts.svg',
    accessLevels: ['none', 'partial', 'full']
  }, {
    name: 'Country Reports',
    key: 'desks',
    link: 'https://desks.worldjewishcongress.org',
    icon: '@weareevermore/satellite/images/apps/desks.svg',
    accessLevels: ['none', 'partial', 'full']
  }, {
    name: 'Events',
    key: 'gum',
    link: 'https://gum.worldjewishcongress.org',
    icon: '@weareevermore/satellite/images/apps/gum.svg',
    accessLevels: ['none', 'wjc_user', 'wjc_editor', 'admin']
  }, {
    name: 'Photos',
    key: 'photos',
    link: 'https://zum.worldjewishcongress.org',
    icon: '@weareevermore/satellite/images/apps/photos.svg',
    accessLevels: ['none', 'viewer', 'wjc_staff', 'admin']
  }, {
    name: 'Security',
    key: 'security',
    link: 'https://security.worldjewishcongress.org',
    icon: '@weareevermore/satellite/images/apps/security.svg',
    accessLevels: ['none', 'partial', 'full']
  }, {
    name: 'JDCorps Intranet',
    key: 'jdcorps',
    link: 'https://jdcorps.worldjewishcongress.org',
    icon: '@weareevermore/satellite/images/apps/jdcorps.svg',
    accessLevels: ['none', 'member', 'leader', 'admin']
  }];
  _exports.default = _default;
});