define("@weareevermore/satellite/services/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DialogMessage = void 0;
  var DialogMessage = Ember.Object.extend({
    title: null,
    message: 'Are you sure?',
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm'
  });
  _exports.DialogMessage = DialogMessage;

  var _default = Ember.Service.extend({
    message: null,
    _deferred: null,
    confirm: function confirm(options) {
      if (this.get('message') === null) {
        this._deferred = Ember.RSVP.defer();
        this.set('message', DialogMessage.create(options));
      }

      return this._deferred.promise;
    },
    confirmDelete: function confirmDelete() {
      return this.confirm({
        title: 'Are you sure?',
        message: 'Deleting a record is final and deleted records cannot be restored.',
        confirmLabel: 'Delete'
      });
    },
    onCancel: function onCancel() {
      // Catch the error if no catch was implemented
      this._deferred.promise.catch(function () {});

      this._deferred.reject();

      this._deferred = null;
      this.set('message', null);
    },
    onConfirm: function onConfirm() {
      this._deferred.resolve();

      this._deferred = null;
      this.set('message', null);
    }
  });

  _exports.default = _default;
});