define("@weareevermore/shuttle/models/shuttle-membership", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Attributes
    position: (0, _attr.default)('string'),
    department: (0, _attr.default)('string'),
    inOfficeSince: (0, _attr.default)('date'),
    inOfficeUntil: (0, _attr.default)('date'),
    isPrimary: (0, _attr.default)('boolean'),
    // Relations
    contacts: (0, _relationships.hasMany)('shuttle-contact'),
    institution: (0, _relationships.belongsTo)('shuttle-institution'),
    headquarter: (0, _relationships.belongsTo)('shuttle-headquarter'),
    person: (0, _relationships.belongsTo)('shuttle-person', {
      inverse: 'memberships'
    })
  });

  _exports.default = _default;
});