define("@weareevermore/shuttle/components/shuttle-select/create-option", ["exports", "@weareevermore/shuttle/templates/components/shuttle-select/create-option"], function (_exports, _createOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _createOption.default,
    tagName: 'span',
    classNames: ['create-option'],
    createResourceLabel: 'record'
  });

  _exports.default = _default;
});