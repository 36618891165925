define("@weareevermore/satellite/components/blanket", ["exports", "@weareevermore/satellite/utils/test-root-element-id", "@weareevermore/satellite/templates/components/blanket"], function (_exports, _testRootElementId, _blanket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _blanket.default,
    // Actions
    onClick: function onClick() {},
    parentElement: null,
    renderInPlace: false,
    _parentElement: Ember.computed('parentElement', 'renderInPlace', function () {
      if (this.renderInPlace) {
        return this.element;
      } else {
        return this._getParentElement();
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      document.body.classList.add('has-blanket');
    },
    willDestroyElement: function willDestroyElement() {
      document.body.classList.remove('has-blanket');

      this._super();
    },
    actions: {
      handleClick: function handleClick(e) {
        if (e.target.classList.contains('blanket')) {
          this.onClick();
        }
      }
    },
    _getParentElement: function _getParentElement() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var id = this.parentElement || (0, _testRootElementId.default)(config) || 'blanket-wormhole';
      return document.getElementById(id);
    }
  });

  _exports.default = _default;
});