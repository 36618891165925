define("@weareevermore/satellite/templates/components/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yc/krwAn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"icon\",[\"search\"],null],false],[0,\"\\n\\n\"],[7,\"input\",true],[11,\"value\",[22,\"value\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"handleChange\"],null]],[11,\"onkeydown\",[28,\"action\",[[23,0,[]],\"handleEscape\"],null]],[11,\"placeholder\",[22,\"placeholder\"]],[10,\"class\",\"search-input flex-auto test-search\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"search-loader-wrapper flex-row flex-center flex-align-center\"],[8],[0,\"\\n    \"],[5,\"loader\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clear\"],null]],[10,\"class\",\"circle-btn btn-blank test-clear-search\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"icon\",[\"close\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/search.hbs"
    }
  });

  _exports.default = _default;
});