define("@weareevermore/satellite/components/radio-buttons/item", ["exports", "@weareevermore/satellite/templates/components/radio-buttons/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _item.default,
    tagName: 'label',
    classNameBindings: [':field-label-radio', 'disabled'],
    isChecked: Ember.computed('checked', 'value', function () {
      return this.checked === this.value;
    }),
    actions: {
      change: function change() {
        this.get('on-change')(this.value);
      }
    }
  });

  _exports.default = _default;
});