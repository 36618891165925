define("@weareevermore/satellite/components/search", ["exports", "@weareevermore/satellite/templates/components/search"], function (_exports, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _search.default,
    classNames: ['search', 'flex-row', 'flex-center'],
    autofocus: false,
    onChange: function onChange() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.autofocus) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this.element.querySelector('input').focus();
        });
      }
    },
    actions: {
      handleChange: function handleChange(e) {
        this.onChange(e.target.value);
      },
      handleEscape: function handleEscape(event) {
        if (event.keyCode === 27) {
          this._clear();
        }
      },
      clear: function clear() {
        this._clear();
      }
    },
    _clear: function _clear() {
      this.onChange('');
    }
  });

  _exports.default = _default;
});