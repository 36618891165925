define("@weareevermore/satellite/components/notify", ["exports", "@weareevermore/satellite/templates/components/notify"], function (_exports, _notify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    layout: _notify.default,
    classNameBindings: [':notify'],
    messages: Ember.computed.reads('notify.messages'),
    actions: {
      removeMessage: function removeMessage(message) {
        this.get('messages').removeObject(message);
      }
    }
  });

  _exports.default = _default;
});