define("@weareevermore/wjc-auth/mixins/authenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    _skipAuthentication: false,
    beforeModel: function beforeModel(transition) {
      if (!this._skipAuthentication && !this.get('session.isAuthenticated')) {
        transition.abort();
        transition.send('login');
      } else {
        return this._super(transition);
      }
    }
  });

  _exports.default = _default;
});