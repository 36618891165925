define("@weareevermore/satellite/templates/components/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ahURhCV",
    "block": "{\"symbols\":[\"dd\",\"&attrs\",\"&default\"],\"statements\":[[5,\"basic-dropdown\",[[12,\"class\",[22,\"dropdownClass\"]]],[[\"@horizontalPosition\",\"@renderInPlace\"],[\"right\",[22,\"renderInPlace\"]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"Trigger\"]],[[12,\"class\",[22,\"triggerClass\"]],[13,2]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"icon\",[[24,[\"icon\"]]],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"Content\"]],[[12,\"class\",\"-drop-fade\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"dropdown-content\"],[8],[0,\"\\n      \"],[14,3,[[28,\"hash\",null,[[\"LinkTo\",\"Item\",\"Separator\"],[[28,\"component\",[\"dropdown/link-to\"],[[\"closedropdown\"],[[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"close\"]]],null]]]],[28,\"component\",[\"dropdown/item\"],[[\"closedropdown\"],[[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"close\"]]],null]]]],[28,\"component\",[\"dropdown/separator\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/dropdown.hbs"
    }
  });

  _exports.default = _default;
});