define("@weareevermore/shuttle/services/shuttle-full-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    hasFullAccess: function hasFullAccess() {
      var access = false;

      if (this.get('session.isAuthenticated')) {
        return this.get('session.user.apps.contacts') == 'full';
      }

      return access;
    }
  });

  _exports.default = _default;
});