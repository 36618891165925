define("@weareevermore/shuttle/templates/components/shuttle-preview/field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vYyaGnfI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"shuttle-preview-field-label\"],[10,\"data-autoid\",\"label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-field-value\"],[10,\"data-autoid\",\"value\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isEmpty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-field-value-empty\"],[8],[0,\"Empty\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"value\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/field.hbs"
    }
  });

  _exports.default = _default;
});