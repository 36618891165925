define("@weareevermore/shuttle/templates/components/shuttle-mounter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0SfuVBMR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"widget\",\"currentState\"]]],null,{\"statements\":[[0,\"  \"],[5,\"modal\",[],[[\"@title\",\"@size\",\"@close\"],[\"Contacts\",\"620px\",[28,\"action\",[[23,0,[]],[24,[\"widget\",\"closeAction\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"shuttle-mounter-content modal-content modal-content-no-footer\"],[8],[0,\"\\n      \"],[5,\"shuttle-widget\",[[12,\"data-autoid\",\"shuttle-widget\"]],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-mounter.hbs"
    }
  });

  _exports.default = _default;
});