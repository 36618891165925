define("@weareevermore/satellite/components/dropdown", ["exports", "@weareevermore/satellite/templates/components/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dropdown.default,
    tagName: '',
    icon: 'more_vert',
    renderInPlace: false,
    dropdownClass: Ember.computed('renderInPlace', 'class', function () {
      var base = ['dropdown'];

      if (this.get('renderInPlace')) {
        base.push(this.get('class'));
      }

      return base.join(' ');
    }),
    triggerClass: Ember.computed('renderInPlace', 'class', function () {
      var base = ['dropdown-trigger', 'circle-btn', 'circle-btn-blank'];

      if (!this.get('renderInPlace')) {
        base.push(this.class);
      }

      return base.join(' ');
    })
  });

  _exports.default = _default;
});