define("@weareevermore/satellite/templates/components/blanket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VEuAbwvR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[24,[\"_parentElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[4,\"transition-group\",null,[[\"click\",\"transitionName\",\"class\"],[[28,\"action\",[[23,0,[]],\"handleClick\"],null],\"blanket\",[28,\"concat\",[\"blanket \",[24,[\"class\"]]],null]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/blanket.hbs"
    }
  });

  _exports.default = _default;
});