define("@weareevermore/wjc-auth/mixins/adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),

    get headers() {
      return this.session.requestHeaders();
    },

    handleResponse: function handleResponse(status) {
      if (status === 401) {
        this.session.invalidate();
        return true;
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});