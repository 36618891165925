define("@weareevermore/shuttle/components/shuttle-slot/form", ["exports", "@weareevermore/shuttle/templates/components/shuttle-slot/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _form.default,
    classNameBindings: [':shuttle-slot-form', 'isActive:is-active:is-not-active'],
    isActive: false,
    isDisabled: Ember.computed.notEmpty('selected'),
    selectType: Ember.computed('type', function () {
      return this.get('type') === 'people' ? 'person' : 'institution';
    }),
    selectLabel: Ember.computed('type', function () {
      return this.get('type') === 'people' ? 'Search for a person...' : 'Search for an institution...';
    }),
    actions: {
      handleChange: function handleChange(record) {
        var _this = this;

        this.set('selected', record);
        this.onChange(record).then(function () {
          if (!_this.get('isDestroyed')) {
            _this.setProperties({
              isActive: false,
              selected: null
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});