define("@weareevermore/satellite/components/dropdown/link-to", ["exports", "@weareevermore/satellite/components/dropdown/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend(_mixin.default);

  _exports.default = _default;
});