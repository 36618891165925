define("@weareevermore/satellite/templates/components/satellite-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wRKnPb9E",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"isLoading\"]]],null]],null,{\"statements\":[[0,\"  \"],[14,2,[[24,[\"result\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"promise-loader flex-row flex-center\"],[10,\"data-autoid\",\"promise-loader\"],[8],[0,\"\\n    \"],[5,\"loader\",[],[[\"@size\"],[16]]],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"spacer-left-small\"],[8],[0,\"Loading...\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/satellite-promise.hbs"
    }
  });

  _exports.default = _default;
});