define("@weareevermore/shuttle/models/shuttle-relation", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    type: (0, _attr.default)('string'),
    // Relations
    person: (0, _relationships.belongsTo)('shuttle-person', {
      inverse: 'relations'
    }),
    relatedTo: (0, _relationships.belongsTo)('shuttle-person', {
      inverse: 'beingRelatedTo'
    })
  });

  _exports.default = _default;
});