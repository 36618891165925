define("@weareevermore/shuttle/templates/components/shuttle-person/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1F2IRBxa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"shuttle-preview-title\"],[8],[0,\"New Person\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"shuttle-preview-box box\"],[8],[0,\"\\n  \"],[5,\"shuttle-person/basic-info-form\",[],[[\"@person\",\"@onSave\",\"@onCancel\"],[[22,\"person\"],[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],[24,[\"onCancel\"]]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person/new.hbs"
    }
  });

  _exports.default = _default;
});