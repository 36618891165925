define("@weareevermore/satellite/templates/components/submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ykQHlfUQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_isLoading\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loader\",[],[[\"@size\"],[24]]],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"span\",true],[10,\"class\",\"submit-label\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/submit.hbs"
    }
  });

  _exports.default = _default;
});