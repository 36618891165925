define("@weareevermore/satellite/templates/components/empty-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ekj6o2v5",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[4,\"if\",[[24,[\"hasQuery\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-autoid\",\"empty-message\"],[8],[0,\"There are no \"],[1,[22,\"label\"],false],[0,\" matching the search query.\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"filters\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"spacer-top-small spacer-bottom-small\"],[10,\"data-autoid\",\"empty-message-filter\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"empty-message-filter-label\"],[8],[1,[23,2,[]],false],[9],[0,\": \"],[7,\"em\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onClear\"]]],null]],[10,\"class\",\"btn btn-secondary btn-sm\"],[10,\"data-autoid\",\"empty-message-clear\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"icon\",[\"clear\"],null],false],[0,\" Clear Search\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-autoid\",\"empty-message\"],[8],[0,\"Looks like there are no \"],[1,[22,\"label\"],false],[0,\".\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/empty-message.hbs"
    }
  });

  _exports.default = _default;
});