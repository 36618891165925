define("@weareevermore/wjc-auth/mixins/unauthenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        transition.abort();
        this.transitionTo('application');
      }

      return this._super(transition);
    }
  });

  _exports.default = _default;
});