define("@weareevermore/shuttle/statics/genders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.A([{
    value: 'male',
    label: 'Male'
  }, {
    value: 'female',
    label: 'Female'
  }, {
    value: '',
    label: 'Unspecified'
  }]);

  _exports.default = _default;
});