define("@weareevermore/satellite/components/notify/message", ["exports", "ember-css-transitions/mixins/transition-mixin", "@weareevermore/satellite/templates/components/notify/message"], function (_exports, _transitionMixin, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ICONS = {
    error: 'error',
    success: 'thumb_up',
    info: 'notifications'
  };

  var _default = Ember.Component.extend(_transitionMixin.default, {
    layout: _message.default,
    classNameBindings: [':notify-message', 'message.type'],
    transitionName: 'notify',
    typeIcon: Ember.computed('message.type', function () {
      return ICONS[this.get('message.type')];
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      this._startTimer();
    },
    willDestroyElement: function willDestroyElement() {
      this._stopTimer();

      this._super();
    },
    mouseEnter: function mouseEnter() {
      this._stopTimer();
    },
    mouseLeave: function mouseLeave() {
      this._startTimer();
    },
    click: function click() {
      this._close();
    },
    _close: function _close() {
      this.get('close')(this.get('message'));
    },
    _startTimer: function _startTimer() {
      var _this = this;

      if (this.get('message.isSticky')) {
        return;
      }

      this._timeout = setTimeout(function () {
        _this._close();
      }, this.get('message.closeAfter'));
    },
    _stopTimer: function _stopTimer() {
      if (this._timeout) {
        clearTimeout(this._timeout);
        this._timeout = null;
      }
    }
  });

  _exports.default = _default;
});