define("@weareevermore/shuttle/templates/components/shuttle-person/relation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G1sHqmME",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-auto\"],[8],[0,\"\\n  \"],[5,\"shuttle-person/link\",[[12,\"class\",\"shuttle-preview-list-item-primary\"],[12,\"data-autoid\",\"primary\"]],[[\"@person\"],[[24,[\"relation\",\"relatedTo\"]]]]],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-list-item-secondary\"],[10,\"data-autoid\",\"secondary\"],[8],[1,[24,[\"relation\",\"type\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"onDelete\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onDelete\"]]],null]],[10,\"class\",\"shuttle-preview-list-item-btn circle-btn circle-btn-danger circle-btn-blank\"],[10,\"data-autoid\",\"delete\"],[8],[0,\"\\n    \"],[1,[28,\"icon\",[\"close\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person/relation.hbs"
    }
  });

  _exports.default = _default;
});