define("@weareevermore/satellite/components/modal", ["exports", "@weareevermore/satellite/utils/test-root-element-id", "@weareevermore/satellite/templates/components/modal"], function (_exports, _testRootElementId, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _modal.default,
    closeOnBackdropClick: true,
    parentElement: null,
    position: 'right',
    renderInPlace: false,
    size: '540px',
    _parentElement: Ember.computed('parentElement', 'renderInPlace', function () {
      if (this.renderInPlace) {
        return this.element;
      } else {
        return this._getParentElement();
      }
    }),
    modalSize: Ember.computed('size', function () {
      return Ember.String.htmlSafe("width: ".concat(Ember.get(this, 'size'), ";"));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(Ember.isEmpty(this.position) || /^right|left|center$/.test(this.position)) && Ember.assert('The position of the modal can be set only to right, left or center', Ember.isEmpty(this.position) || /^right|left|center$/.test(this.position)));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      document.body.classList.add('has-modal');

      this._clickHandler = function (e) {
        if (_this.get('closeOnBackdropClick') && e.target.id === 'modal-backdrop') {
          _this.send('close');
        }
      };

      document.getElementById('modal-backdrop').addEventListener('click', this._clickHandler);
    },
    willDestroyElement: function willDestroyElement() {
      document.body.classList.remove('has-modal');
      document.getElementById('modal-backdrop').removeEventListener('click', this._clickHandler);

      this._super();
    },
    actions: {
      close: function close() {
        if (this._preventClose()) {
          return;
        }

        this.get('close')();
      }
    },
    _getParentElement: function _getParentElement() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var id = this.parentElement || (0, _testRootElementId.default)(config) || 'modal-wormhole';
      return document.getElementById(id);
    },
    _preventClose: function _preventClose() {
      var beforeClose = this.get('beforeclose');
      return (beforeClose ? beforeClose() : true) === false;
    }
  });

  _exports.default = _default;
});