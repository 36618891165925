define("@weareevermore/shuttle/components/shuttle-select/create-person", ["exports", "@weareevermore/shuttle/components/shuttle-select/create-option"], function (_exports, _createOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createOption.default.extend({
    createResourceLabel: 'person'
  });

  _exports.default = _default;
});