define("@weareevermore/shuttle/templates/components/shuttle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KJOF0qUk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"widget\",\"tempAction\"]],\"people\"],null]],[10,\"class\",\"nav-miniapp animate reset-button\"],[10,\"data-autoid\",\"shuttle-button\"],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[1,[28,\"icon\",[\"contacts\"],null],false],[0,\" Contacts\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-button.hbs"
    }
  });

  _exports.default = _default;
});