define("@weareevermore/satellite/components/submit", ["exports", "@weareevermore/satellite/templates/components/submit"], function (_exports, _submit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _submit.default,
    classNameBindings: [':btn', ':submit', '_isLoading:-is-loading'],
    attributeBindings: ['type', 'resolveDisabled:disabled'],
    tagName: 'button',
    type: 'button',
    _isLoading: Ember.computed.or('isSaving', 'isLoading'),
    resolveDisabled: Ember.computed.or('_isLoading', 'disabled')
  });

  _exports.default = _default;
});