define("@weareevermore/satellite/components/widgets", ["exports", "@weareevermore/satellite/utils/is-touch-device", "@weareevermore/satellite/templates/components/widgets"], function (_exports, _isTouchDevice, _widgets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _widgets.default,
    didInsertElement: function didInsertElement() {
      var loader = document.getElementById('app-loader');

      if (loader) {
        loader.remove();
      }

      if (_isTouchDevice.default) {
        document.body.classList.add('touch-device');
      }
    }
  });

  _exports.default = _default;
});