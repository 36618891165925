define("@weareevermore/shuttle/templates/components/shuttle-people-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oCIUj88s",
    "block": "{\"symbols\":[\"people\",\"&default\"],\"statements\":[[4,\"satellite-promise\",[[24,[\"peoplePromise\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-people-loader.hbs"
    }
  });

  _exports.default = _default;
});