define("@weareevermore/shuttle/components/shuttle-preview/accordion", ["exports", "@weareevermore/shuttle/templates/components/shuttle-preview/accordion"], function (_exports, _accordion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Accordion = Ember.Component.extend({
    layout: _accordion.default,
    classNameBindings: [':shuttle-preview-accordions', 'hasOpenedItem'],
    key: 'id',
    hasOpenedItem: Ember.computed.notEmpty('currentIndex'),
    currentIndex: Ember.computed('disabled', {
      get: function get() {
        if (this.get('disabled')) {
          this._currentIndex = null;
        }

        return this._currentIndex;
      },
      set: function set(key, value) {
        if (this.get('disabled')) {
          value = null;
        }

        this._currentIndex = value;
        return this._currentIndex;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentIndex', this.get('defaultCurrentIndex'));
    },
    actions: {
      expandSection: function expandSection(index) {
        index = this.get('currentIndex') === index ? null : index;
        this.set('currentIndex', index);

        if (this.onChange) {
          this.onChange(index);
        }
      },
      handleOnLinkClick: function handleOnLinkClick(item) {
        return this.onLinkClick(item);
      }
    }
  });
  Accordion.reopenClass({
    positionalParams: ['collection']
  });
  var _default = Accordion;
  _exports.default = _default;
});