define("@weareevermore/satellite/transforms/datepicker", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    // Always parse the date in the current timezone
    deserialize: function deserialize(value) {
      if (value && value.indexOf('Z') > -1) {
        return new Date(value.replace('Z', ''));
      } else {
        return value;
      }
    },
    serialize: function serialize(value) {
      return value ? this._convertToUTC(value) : null;
    },
    _convertToUTC: function _convertToUTC(value) {
      return (0, _moment.default)(value).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    }
  });

  _exports.default = _default;
});