define("@weareevermore/satellite/components/empty-message", ["exports", "@weareevermore/satellite/templates/components/empty-message"], function (_exports, _emptyMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _emptyMessage.default,
    classNames: ['empty-message'],
    label: 'records',
    filters: Object.freeze({}),
    // Actions
    onClear: Ember.RSVP.resolve,
    hasQuery: Ember.computed('filters', function () {
      var filters = this.get('filters');
      return Object.keys(filters).filter(function (key) {
        return !Ember.isEmpty(filters[key]);
      }).length > 0;
    })
  });

  _exports.default = _default;
});