define("@weareevermore/satellite/components/datepicker-field", ["exports", "pikaday", "@weareevermore/satellite/mixins/base-input", "moment", "@weareevermore/satellite/templates/components/datepicker-field"], function (_exports, _pikaday, _baseInput, _moment, _datepickerField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseInput.default, {
    layout: _datepickerField.default,
    classNames: ['field-datepicker'],
    format: 'DD MMMM YYYY',
    yearRange: 10,
    // eslint-disable-next-line ember/no-observers
    onValueChange: Ember.observer('value', function () {
      this.setDate();
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      var options = {
        field: this.element.querySelector('input'),
        format: this.format,
        yearRange: this._getYearRange(),
        firstDay: 1,
        onSelect: Ember.run.bind(this, this._onSelect),
        toString: function toString(date, format) {
          return (0, _moment.default)(date).format(format);
        },
        parse: function parse(dateString, format) {
          return (0, _moment.default)(dateString, format).toDate();
        }
      };
      this._picker = new _pikaday.default(options);
      this.setDate();
    },
    setDate: function setDate() {
      this._picker.setDate(this.value, true);
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      Ember.run(function () {
        return _this._picker.destroy();
      });

      this._super();
    },
    _onSelect: function _onSelect() {
      this.set('value', this._picker.getDate());
    },
    _getYearRange: function _getYearRange() {
      var range = this.yearRange || 10;

      if (typeof range === 'string') {
        range = range.split(',');

        if (range[1] === 'current') {
          range[1] = new Date().getFullYear();
        }
      }

      return range;
    }
  });

  _exports.default = _default;
});