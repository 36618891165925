define("@weareevermore/satellite/templates/components/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xIMZj+CD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"pagination-main flex-row flex-center flex-align-center\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"disabled\",[22,\"prevButtonDisabled\"]],[12,\"class\",\"pagination-btn circle-btn circle-btn-blank\"],[12,\"data-autoid\",\"previous-button\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"changeToPreviousPage\"]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"icon\"],[8],[0,\"arrow_back\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"pagination-info\"],[8],[0,\"\\n    \"],[7,\"input\",true],[11,\"value\",[22,\"page\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"handleInputChange\"],null]],[10,\"class\",\"pagination-info-input\"],[10,\"data-autoid\",\"current-page\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n    of \"],[7,\"span\",true],[10,\"data-autoid\",\"last-page\"],[8],[1,[22,\"lastPage\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",false],[12,\"disabled\",[22,\"nextButtonDisabled\"]],[12,\"class\",\"pagination-btn circle-btn circle-btn-blank\"],[12,\"data-autoid\",\"next-button\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"changeToNextPage\"]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"icon\"],[8],[0,\"arrow_forward\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"pagination-meta test-pagination-meta\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"total\"]],0],null]],null,{\"statements\":[[0,\"    Showing 0 of 0\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Showing \"],[1,[22,\"fromRecord\"],false],[0,\"-\"],[1,[22,\"toRecord\"],false],[0,\" of \"],[1,[22,\"total\"],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/pagination.hbs"
    }
  });

  _exports.default = _default;
});