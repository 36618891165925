define("@weareevermore/satellite/components/evermore", ["exports", "@weareevermore/satellite/templates/components/evermore"], function (_exports, _evermore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _evermore.default,
    tagName: 'a',
    attributeBindings: ['href', 'target', 'rel'],
    classNames: ['evermore', 'flex-row', 'flex-center'],
    href: 'http://weareevermore.com/',
    target: '_blank',
    rel: 'nofollow'
  });

  _exports.default = _default;
});