define("@weareevermore/shuttle/models/shuttle-country", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Attributes
    name: (0, _attr.default)('string'),
    iso: (0, _attr.default)('string'),
    // Relations
    deskOfficer: (0, _relationships.belongsTo)('shuttle-person')
  });

  _exports.default = _default;
});