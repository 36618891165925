define("@weareevermore/satellite/components/form", ["exports", "@weareevermore/satellite/templates/components/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Form = Ember.Component.extend({
    layout: _form.default,
    tagName: 'form',
    autofocus: true,
    disabled: false,
    submit: function submit(e) {
      e.preventDefault();
      this.onSubmit();
    },
    didInsertElement: function didInsertElement() {
      this._super();

      if (this.autofocus) {
        var node = this.element.querySelector('input, textarea, select');

        if (node) {
          node.focus();
        }
      }
    }
  });
  Form.reopenClass({
    positionalParams: ['model']
  });
  var _default = Form;
  _exports.default = _default;
});