define("@weareevermore/shuttle/templates/components/shuttle-preview/edit-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "80CDStkA",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"editMode\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[28,\"action\",[[23,0,[]],\"setEditMode\",false],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[28,\"shuttle-full-access\",null,null]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"shuttle-preview-box-edit circle-btn circle-btn-blank animate\"],[12,\"data-autoid\",\"toggle-edit-mode\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"setEditMode\",true]],[8],[0,\"\\n      \"],[1,[28,\"icon\",[\"edit\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/edit-mode.hbs"
    }
  });

  _exports.default = _default;
});