define("@weareevermore/satellite/templates/components/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ewTI8wcE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[24,[\"_parentElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[4,\"transition-group\",null,[[\"transitionName\",\"class\",\"id\"],[\"modal\",[28,\"concat\",[\"modal-backdrop\",[24,[\"class\"]]],null],\"modal-backdrop\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"modal \",[22,\"position\"]]]],[11,\"style\",[22,\"modalSize\"]],[10,\"id\",\"modal\"],[8],[0,\"\\n      \"],[7,\"header\",true],[10,\"class\",\"modal-header flex-row flex-center\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"data-autoid\",\"modal-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"modal-close circle-btn circle-btn-blank\"],[12,\"data-autoid\",\"close-modal\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"icon\"],[8],[0,\"close\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/modal.hbs"
    }
  });

  _exports.default = _default;
});