define("@weareevermore/shuttle/services/shuttle-json", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    headers: Ember.computed(function () {
      return this.get('session').requestHeaders();
    }).volatile(),
    request: function request(url, options) {
      options = Ember.assign({
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json; charset=UTF-8'
      }, options);

      if (options.data && options.type !== 'GET') {
        options.data = JSON.stringify(options.data);
      }

      return this._super(url, options);
    },
    getSlots: function getSlots(groups) {
      var data = {
        groups: groups
      };
      return this.request('/api/jb2/slots/export', {
        type: 'POST',
        data: data
      });
    }
  });

  _exports.default = _default;
});