define("@weareevermore/shuttle/templates/components/shuttle-person-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LSAmMbcc",
    "block": "{\"symbols\":[\"person\",\"&default\"],\"statements\":[[4,\"satellite-promise\",[[24,[\"personPromise\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"shuttle-loader-not-found\"],[8],[0,\"Empty\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person-loader.hbs"
    }
  });

  _exports.default = _default;
});