define("@weareevermore/shuttle/components/shuttle-institution/basic-info-form", ["exports", "@weareevermore/shuttle/templates/components/shuttle-institution/basic-info-form"], function (_exports, _basicInfoForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _basicInfoForm.default,
    dialog: Ember.inject.service('dialog'),
    isSaving: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('model', Ember.Object.create({
        name: this.get('institution.name'),
        officialName: this.get('institution.officialName'),
        abbreviation: this.get('institution.abbreviation'),
        pointPerson: this.get('institution.pointPerson'),
        tags: this.get('institution.tags').toArray()
      }));
    },
    actions: {
      save: function save() {
        this.set('isSaving', true);
        this.onSave(this.get('model'));
      },
      archive: function archive() {
        var _this = this;

        var onArchive = this.onArchive;
        this.get('dialog').confirm({
          title: 'Are you sure?',
          message: 'Once archived you won\'t see the record anymore',
          confirmLabel: 'Archive',
          cancelLabel: 'Cancel'
        }).then(function () {
          _this.set('isSaving', true);

          onArchive();
        });
      }
    }
  });

  _exports.default = _default;
});