define("@weareevermore/satellite/templates/components/radio-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A7dP38Ta",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"label\",true],[10,\"class\",\"field-label\"],[8],[1,[22,\"resolvedLabel\"],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"field-radio-buttons\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"collection\"]]],null,{\"statements\":[[0,\"    \"],[5,\"radio-buttons/item\",[],[[\"@name\",\"@checked\",\"@value\",\"@label\",\"@disabled\",\"@on-change\"],[[22,\"elementId\"],[22,\"checked\"],[23,1,[\"value\"]],[23,1,[\"label\"]],[28,\"or\",[[23,1,[\"disabled\"]],[24,[\"disabled\"]]],null],[28,\"action\",[[23,0,[]],\"change\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"field-errors\"],[8],[1,[22,\"errorMessages\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/radio-buttons.hbs"
    }
  });

  _exports.default = _default;
});