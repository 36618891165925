define("@weareevermore/shuttle/components/shuttle-person-loader", ["exports", "@weareevermore/shuttle/templates/components/shuttle-person-loader"], function (_exports, _shuttlePersonLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShuttlePersonLoader = Ember.Component.extend({
    layout: _shuttlePersonLoader.default,
    personService: Ember.inject.service('shuttle-person'),
    personPromise: Ember.computed('personId', function () {
      return this.get('personId') ? this.get('personService').findRecord(this.get('personId')) : null;
    })
  });
  ShuttlePersonLoader.reopenClass({
    positionalParams: ['personId']
  });
  var _default = ShuttlePersonLoader;
  _exports.default = _default;
});