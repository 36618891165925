define("@weareevermore/shuttle/templates/components/shuttle-slot/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mevYv0az",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-auto\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"shuttle-slot-item-title\"],[10,\"data-autoid\",\"slot-main\"],[8],[0,\"\\n      \"],[1,[24,[\"record\",\"selectTitle\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"shuttle-slot-item-info\"],[10,\"data-autoid\",\"slot-secondary\"],[8],[0,\"\\n      \"],[1,[24,[\"record\",\"selectSubtitle\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"shuttle-slot-item-btn circle-btn circle-btn-danger circle-btn-blank\"],[12,\"data-autoid\",\"slot-delete\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"onDelete\"]]],null]],[[\"bubbles\"],[false]]],[8],[0,\"\\n  \"],[1,[28,\"icon\",[\"close\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-slot/item.hbs"
    }
  });

  _exports.default = _default;
});