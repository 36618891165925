define("@weareevermore/shuttle/templates/components/shuttle-preview/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PfHePS4H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"shuttle-icon shuttle-icon-\",[23,0,[\"icon\"]],\" shuttle-preview-contact-icon\"]]],[8],[9],[0,\"\\n\"],[7,\"a\",true],[11,\"href\",[29,[[22,\"href\"]]]],[11,\"title\",[29,[[24,[\"contact\",\"value\"]]]]],[10,\"target\",\"_blank\"],[10,\"class\",\"shuttle-preview-contact-value\"],[10,\"data-autoid\",\"contact-value\"],[8],[0,\"\\n  \"],[1,[24,[\"contact\",\"value\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"contact\",\"isPrimary\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-contact-is-primary\"],[10,\"data-autoid\",\"contact-is-primary\"],[8],[0,\"primary\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/contact.hbs"
    }
  });

  _exports.default = _default;
});