define("@weareevermore/satellite/components/dropdown/item", ["exports", "@weareevermore/satellite/templates/components/dropdown/item", "@weareevermore/satellite/components/dropdown/mixin"], function (_exports, _item, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mixin.default, {
    layout: _item.default,
    tagName: 'button',
    attributeBindings: ['href', 'download', 'title']
  });

  _exports.default = _default;
});