define("@weareevermore/shuttle/services/shuttle-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var bindAction = function bindAction(method) {
    return Ember.computed(function () {
      return Ember.run.bind(this, this[method]);
    });
  };

  var copyAndMerge = function copyAndMerge() {
    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    return Ember.assign.apply(void 0, [{}].concat(objects));
  };

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this._reset();

      this.resetDefaults();
    },
    currentState: Ember.computed('states.[]', 'tempState', function () {
      return this.get('tempState') || this.get('states.lastObject');
    }),
    previousState: Ember.computed('states.[]', function () {
      var states = this.get('states');
      var length = states.get('length');
      return length > 1 ? states[length - 2] : null;
    }),
    // Helper props that can be passed to the action helper
    // it maybe a better idea to create helpers?
    openAction: bindAction('open'),
    tempAction: bindAction('temp'),
    alterAction: bindAction('alter'),
    backAction: bindAction('back'),
    closeAction: bindAction('close'),
    open: function open(component) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var nextState = copyAndMerge(this._defaults, options, {
        component: component
      });

      this._pushState(nextState);
    },
    temp: function temp(component) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var tempState = copyAndMerge(this._defaults, options, {
        component: component
      });
      this.set('tempState', tempState);
    },
    alter: function alter(options) {
      var nextState = copyAndMerge(this._defaults, this.get('currentState'), options);

      this._pushState(nextState);
    },
    back: function back() {
      this._popState();
    },
    close: function close() {
      this._reset();
    },
    setDefaults: function setDefaults(defaults) {
      this._defaults = defaults || {};
    },
    getDefaults: function getDefaults() {
      return this._defaults;
    },
    resetDefaults: function resetDefaults() {
      this._defaults = {};
    },
    _pushState: function _pushState(state) {
      this.set('tempState', null);
      this.get('states').pushObject(state);
    },
    _popState: function _popState() {
      this.set('tempState', null);

      if (this.get('states.length') > 1) {
        this.get('states').popObject();
      }
    },
    _reset: function _reset() {
      this.setProperties({
        states: Ember.A([]),
        tempState: null
      });
    }
  });

  _exports.default = _default;
});