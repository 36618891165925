define("@weareevermore/shuttle/components/shuttle-preview/contact-form", ["exports", "@weareevermore/shuttle/templates/components/shuttle-preview/contact-form", "@weareevermore/shuttle/statics/contact-labels"], function (_exports, _contactForm, _contactLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ICON_MAP = {
    'mobile/cell': 'mobile',
    'web site': 'website'
  };

  var _default = Ember.Component.extend({
    layout: _contactForm.default,
    contact: null,
    contactLabels: _contactLabels.default,
    classNameBindings: [':shuttle-preview-contact-form', ':flex-row', ':flex-center', 'areFieldsDisabled:shuttle-preview-contact-form-disabled'],
    areFieldsDisabled: Ember.computed.or('disabled', 'contact.isDeleted'),
    icon: Ember.computed('contact.attrs.label', function () {
      var label = (this.get('contact.attrs.label') || '').toLowerCase();
      return ICON_MAP[label] || label;
    })
  });

  _exports.default = _default;
});