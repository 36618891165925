define("@weareevermore/satellite/components/input-field", ["exports", "@weareevermore/satellite/mixins/base-input", "@weareevermore/satellite/templates/components/input-field"], function (_exports, _baseInput, _inputField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseInput.default, {
    layout: _inputField.default,
    type: 'text',
    actions: {
      onChange: function onChange(e) {
        if (this.onChange) {
          this.onChange(e.target.value);
        } else {
          this.set('value', e.target.value);
        }
      }
    }
  });

  _exports.default = _default;
});