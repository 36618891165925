define("@weareevermore/satellite/components/pagination", ["exports", "@weareevermore/satellite/templates/components/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pagination.default,
    classNames: ['pagination'],
    prevButtonDisabled: Ember.computed('page', function () {
      return this.get('page') <= 1;
    }),
    nextButtonDisabled: Ember.computed('page', 'lastPage', function () {
      return this.get('page') >= this.get('lastPage');
    }),
    lastPage: Ember.computed('size', 'total', function () {
      var total = this.get('total') || 1;
      return Math.ceil(total / this.get('size'));
    }),
    fromRecord: Ember.computed('page', 'size', 'total', function () {
      return this.get('size') * (this.get('page') - 1) + 1;
    }),
    toRecord: Ember.computed('page', 'size', 'total', function () {
      var toRecord = this.get('size') * this.get('page');
      return toRecord > this.get('total') ? this.get('total') : toRecord;
    }),
    actions: {
      changeToPreviousPage: function changeToPreviousPage() {
        var previous = this.get('page') - 1;

        if (previous > 0) {
          this.get('onChange')(previous);
        }
      },
      changeToNextPage: function changeToNextPage() {
        var next = this.get('page') + 1;

        if (next <= this.get('lastPage')) {
          this.get('onChange')(next);
        }
      },
      handleInputChange: function handleInputChange(e) {
        var value = e.target.value;

        if (value > 0 && value <= this.get('lastPage')) {
          this.get('onChange')(value);
        }
      }
    }
  });

  _exports.default = _default;
});