define("@weareevermore/satellite/templates/components/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+IK+1mCf",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[7,\"circle\",true],[10,\"class\",\"loader-path\"],[10,\"fill\",\"none\"],[11,\"stroke-width\",[22,\"stroke\"]],[10,\"stroke-linecap\",\"round\"],[11,\"cx\",[22,\"coordinate\"]],[11,\"cy\",[22,\"coordinate\"]],[10,\"r\",\"30\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/loader.hbs"
    }
  });

  _exports.default = _default;
});