define("@weareevermore/shuttle/components/shuttle-widget", ["exports", "@weareevermore/shuttle/templates/components/shuttle-widget"], function (_exports, _shuttleWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _shuttleWidget.default,
    classNames: ['shuttle-widget', 'flex-row'],
    widget: Ember.inject.service('shuttle-widget'),
    state: Ember.computed.readOnly('widget.currentState'),
    actions: {
      open: function open() {
        var _this$get;

        (_this$get = this.get('widget')).open.apply(_this$get, arguments);
      },
      alter: function alter(options) {
        this.get('widget').alter(options);
      },
      openTemp: function openTemp() {
        var _this$get2;

        (_this$get2 = this.get('widget')).temp.apply(_this$get2, arguments);
      },
      openPerson: function openPerson(id) {
        this.get('widget').open('person', {
          personId: id
        });
      },
      openInstitution: function openInstitution(id) {
        this.get('widget').open('institution', {
          institutionId: id
        });
      },
      back: function back() {
        this.get('widget').back();
      }
    }
  });

  _exports.default = _default;
});