define("@weareevermore/shuttle/components/shuttle-list/institution", ["exports", "@weareevermore/shuttle/templates/components/shuttle-list/institution"], function (_exports, _institution) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _institution.default,
    classNames: ['shuttle-list-item', 'animate'],
    info: Ember.computed('institution.{abbreviation,officialName}', function () {
      return Ember.A([this.get('institution.abbreviation'), this.get('institution.officialName')]).compact().join(' / ');
    })
  });

  _exports.default = _default;
});