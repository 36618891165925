define("@weareevermore/satellite/components/empty-state", ["exports", "@weareevermore/satellite/templates/components/empty-state"], function (_exports, _emptyState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_ICONS = void 0;
  var DEFAULT_ICONS = Object.freeze(['clear_all', 'face', 'landscape', 'map', 'panorama_horizontal', 'sentiment_satisfied', 'weekend']);
  _exports.DEFAULT_ICONS = DEFAULT_ICONS;

  var _default = Ember.Component.extend({
    layout: _emptyState.default,
    classNames: ['empty-state'],
    title: 'Looks like this page is still empty',
    init: function init() {
      this._super.apply(this, arguments);

      this._setDefaultIcon();
    },
    _setDefaultIcon: function _setDefaultIcon() {
      if (!this.icon) {
        var randomIndex = Math.floor(Math.random() * DEFAULT_ICONS.length);
        this.icon = DEFAULT_ICONS[randomIndex];
      }
    }
  });

  _exports.default = _default;
});