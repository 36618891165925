define("@weareevermore/satellite/templates/components/notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5iNhep1V",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"  \"],[5,\"notify/message\",[],[[\"@message\",\"@close\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"removeMessage\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/notify.hbs"
    }
  });

  _exports.default = _default;
});