define("@weareevermore/shuttle/components/shuttle-preview/field", ["exports", "@weareevermore/shuttle/templates/components/shuttle-preview/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _field.default,
    classNameBindings: [':shuttle-preview-field'],
    isEmpty: Ember.computed.empty('value')
  });

  _exports.default = _default;
});