define("@weareevermore/satellite/components/power-select-field", ["exports", "@weareevermore/satellite/mixins/base-input", "@weareevermore/satellite/templates/components/power-select-field"], function (_exports, _baseInput, _powerSelectField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseInput.default, {
    layout: _powerSelectField.default,
    selected: Ember.computed.alias('value'),
    classNames: ['power-select'],
    allowClear: true,
    searchPlaceholder: 'Filter the list...',
    searchField: 'label',
    searchEnabled: true,
    selectedOption: Ember.computed('selected', 'collection', function () {
      var _this = this;

      return this.get('collection') && Ember.A(this.get('collection')).find(function (option) {
        return option.value === _this.get('selected');
      });
    }),
    resolveTriggerClass: Ember.computed('triggerClass', function () {
      return "power-select-trigger ".concat(this.get('triggerClass') || '');
    }),
    resolveDropdownClass: Ember.computed('dropdownClass', function () {
      return "power-select-dropdown ".concat(this.get('dropdownClass') || '');
    }),
    actions: {
      handleChange: function handleChange(option) {
        this.set('selected', option ? option.value : null);
      }
    }
  });

  _exports.default = _default;
});