define("@weareevermore/satellite/templates/components/evermore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i/g33pw6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"class\",\"evermore-logo\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"20\"],[10,\"height\",\"16\"],[10,\"viewBox\",\"0 0 20 16\"],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"fill\",\"#4a4a4a\"],[10,\"d\",\"M11.01 14.97l-.67-.66 6.18-6.19h2.49l-6.84 6.85zm-3.26 0l-.79-.86L.97 8.12h2.46l1.73 1.73 6.42-6.42 1.24 1.25L6.4 11.1l1.97 1.96 6.42-6.41 1.24 1.24-5.89 5.9-1.27 1.18zM8.42.51c.18 0 .34.08.49.25l.71.7-6.2 6.2H.93l6.9-6.9a.93.93 0 0 1 .59-.25zm3.72.23l6.92 6.92h-2.54l-1.73-1.74-6.42 6.42-1.24-1.24 6.41-6.42-1.96-1.97-6.42 6.42-1.25-1.24L11.06.74c.3-.3.78-.3 1.08 0zM10.7.38l-.72.72-.7-.7C9.03.14 8.74 0 8.42 0c-.52 0-.91.35-.95.4L0 7.87l6.59 6.59.93 1.03h1.56l.88-.84.83.84h1.59l7.61-7.62L12.5.38c-.5-.5-1.31-.5-1.8 0z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"evermore-label\"],[8],[0,\"\\n  Built by Evermore\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/evermore.hbs"
    }
  });

  _exports.default = _default;
});