define("@weareevermore/satellite/templates/components/textarea-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o1JNN3Ji",
    "block": "{\"symbols\":[],\"statements\":[[7,\"textarea\",true],[11,\"value\",[22,\"value\"]],[11,\"rows\",[22,\"rows\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"autofocus\",[22,\"autofocus\"]],[11,\"placeholder\",[22,\"placeholder\"]],[8],[9],[0,\"\\n\"],[7,\"label\",true],[10,\"class\",\"field-label animate\"],[8],[1,[22,\"resolvedLabel\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"field-errors\"],[8],[1,[22,\"errorMessages\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/textarea-field.hbs"
    }
  });

  _exports.default = _default;
});