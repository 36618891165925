define("@weareevermore/satellite/utils/test-root-element-id", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = testRootElementId;

  function testRootElementId(config) {
    var id;

    if (config.environment === 'test' && false
    /* DEBUG */
    && typeof FastBoot === 'undefined') {
      if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
        try {
          id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
        } catch (e) {}
      }

      if (!id) {
        var rootView = document.querySelector('#ember-testing > .ember-view');
        id = rootView && rootView.id;
      }
    }

    return id;
  }
});