define("@weareevermore/satellite/utils/humanize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = humanize;

  function humanize(value) {
    if (value) {
      var result = Ember.String.underscore(value + '').replace(/_/g, ' ');
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  }
});