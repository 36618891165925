define("@weareevermore/shuttle/templates/components/shuttle-select/create-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S9XXIQOX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"icon\",[\"add\"],[[\"class\"],[\"create-option-icon\"]]],false],[0,\"\\nCreate new \"],[1,[22,\"createResourceLabel\"],false],[0,\"...\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-select/create-option.hbs"
    }
  });

  _exports.default = _default;
});