define("@weareevermore/shuttle/components/shuttle-person", ["exports", "@weareevermore/shuttle/templates/components/shuttle-person"], function (_exports, _shuttlePerson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _this = void 0;

  var _default = Ember.Component.extend({
    layout: _shuttlePerson.default,
    store: Ember.inject.service('store'),
    personService: Ember.inject.service('shuttle-person'),
    widget: Ember.inject.service('shuttle-widget'),
    // Actions
    onInstitutionClick: function onInstitutionClick() {
      return _this;
    },
    classNameBindings: [':shuttle-preview', ':shuttle-person', ':flex-column', 'hasFocused:shuttle-preview-has-focused'],
    hasFocused: Ember.computed('currentFocus', 'newMembershipForm', 'newRelationForm', function () {
      return this.get('currentFocus') || this.get('newMembershipForm') || this.get('newRelationForm');
    }),
    isAccordionDisabled: Ember.computed('currentFocus', 'hasFocused', function () {
      return this.get('hasFocused') && this.get('currentFocus') !== 'membership';
    }),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.get('personService').findRecord(this.get('uuid')).then(function (person) {
        _this2.set('person', person);
      });
      this.set('newMembership', Ember.Object.create({
        contacts: []
      }));
    },
    actions: {
      savePerson: function savePerson(toggleForm, attrs) {
        var person = this.get('person');
        return this.get('personService').updateRecord(person, attrs).then(toggleForm);
      },
      archivePerson: function archivePerson() {
        var _this3 = this;

        var person = this.get('person');
        return this.get('personService').updateRecord(person, {
          isArchived: true
        }).then(function () {
          _this3.get('widget').temp('people');
        });
      },
      saveContacts: function saveContacts(data) {
        var person = this.get('person');
        return this.get('personService').updateContacts(person, data);
      },
      createMembership: function createMembership(data) {
        var person = this.get('person');
        return this.get('personService').createMembership(person, data);
      },
      updateMembership: function updateMembership(membership, data) {
        return this.get('personService').updateMembership(membership, data);
      },
      deleteMembership: function deleteMembership(membership) {
        var _this4 = this;

        return this.get('personService').deleteMembership(membership).then(function () {
          _this4.set('currentFocus', null);
        });
      },
      createRelation: function createRelation(attrs) {
        var person = this.get('person');
        return this.get('personService').createRelation(person, attrs);
      },
      deleteRelation: function deleteRelation(relation) {
        return this.get('personService').deleteRelation(relation);
      },
      setAttrAndUpdateScroll: function setAttrAndUpdateScroll(attrName, value) {
        var _this5 = this;

        this.set(attrName, value);
        Ember.run(function () {
          var event = new Event('update-scroll');

          _this5.element.querySelector('.shuttle-scrollbar').dispatchEvent(event);
        });
      },
      handleMembershipLinkClick: function handleMembershipLinkClick(membership) {
        var institutionId = membership.belongsTo('institution').id();
        return this.onInstitutionClick(institutionId);
      }
    }
  });

  _exports.default = _default;
});