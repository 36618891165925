define("@weareevermore/satellite/templates/components/widgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ZF/Ngg0",
    "block": "{\"symbols\":[],\"statements\":[[5,\"notify\",[],[[],[]]],[0,\"\\n\"],[5,\"dialog\",[],[[],[]]],[0,\"\\n\"],[5,\"evermore\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/widgets.hbs"
    }
  });

  _exports.default = _default;
});