define("@weareevermore/shuttle/templates/components/shuttle-slot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8DXXTQQL",
    "block": "{\"symbols\":[\"record\",\"&default\"],\"statements\":[[5,\"shuttle-slot/header\",[[12,\"data-autoid\",\"slot-header\"]],[[\"@title\",\"@isActive\",\"@onClick\"],[[22,\"title\"],[22,\"isActive\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isActive\"]]],null]],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[4,\"satellite-promise\",[[24,[\"dataPromise\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"shuttle-slot-items\"],[10,\"data-autoid\",\"slot-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"          \"],[5,\"shuttle-slot/item\",[[12,\"data-autoid\",\"slot-item\"]],[[\"@record\",\"@click\",\"@onDelete\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"openWidget\",[23,1,[\"id\"]]],null],[28,\"action\",[[23,0,[]],\"removeRecord\",[23,1,[]]],null]]],{\"statements\":[[0,\"\\n            \"],[14,2,[[23,1,[]]]],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[5,\"shuttle-slot/item\",[[12,\"data-autoid\",\"slot-item\"]],[[\"@record\",\"@click\",\"@onDelete\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"openWidget\",[23,1,[\"id\"]]],null],[28,\"action\",[[23,0,[]],\"removeRecord\",[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"shuttle-slot-empty\"],[10,\"data-autoid\",\"slot-empty\"],[8],[0,\"\\n          The slot appears to be empty.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[5,\"shuttle-slot/form\",[],[[\"@type\",\"@onChange\"],[[22,\"type\"],[28,\"action\",[[23,0,[]],\"addRecord\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-slot.hbs"
    }
  });

  _exports.default = _default;
});