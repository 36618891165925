define("@weareevermore/shuttle/components/shuttle-people-loader", ["exports", "@weareevermore/shuttle/templates/components/shuttle-people-loader"], function (_exports, _shuttlePeopleLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShuttlePeopleLoader = Ember.Component.extend({
    layout: _shuttlePeopleLoader.default,
    store: Ember.inject.service(),
    peoplePromise: Ember.computed('query', function () {
      return this.get('store').query('shuttle-person', this.get('query') || {});
    })
  });
  ShuttlePeopleLoader.reopenClass({
    positionalParams: ['query']
  });
  var _default = ShuttlePeopleLoader;
  _exports.default = _default;
});