define("@weareevermore/shuttle/components/shuttle-list/search", ["exports", "@weareevermore/shuttle/templates/components/shuttle-list/search", "@weareevermore/shuttle/statics/debounce-wait-time"], function (_exports, _search, _debounceWaitTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _search.default,
    store: Ember.inject.service('store'),
    classNames: ['shuttle-list-search'],
    selected: Ember.computed('tags.[]', 'term', function () {
      var tags = this.get('tags').map(function (tag) {
        return {
          label: 'tag',
          value: tag
        };
      });

      if (!Ember.isEmpty(this.get('term'))) {
        tags.unshift({
          label: 'term',
          value: this.get('term')
        });
      }

      return tags;
    }),
    actions: {
      search: function search(term) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          Ember.run.debounce(_this, _this._performSearch, term, resolve, _debounceWaitTime.default);
        });
      },
      handleChange: function handleChange(options) {
        options = options.reduce(this._optionsReducer, {
          tags: [],
          term: null
        });
        this.onChange(options);
      }
    },
    _performSearch: function _performSearch(term, resolve) {
      return this.get('store').query('shuttle-tag', {
        sort: 'name',
        filter: {
          query: term
        }
      }).then(function (tags) {
        var result = [{
          groupName: 'Free text',
          options: [{
            label: 'term',
            value: term
          }]
        }];

        if (tags.get('length') > 0) {
          result.push({
            groupName: 'Suggested tags',
            options: tags.map(function (tag) {
              return {
                label: 'tag',
                value: tag.get('name')
              };
            })
          });
        }

        resolve(result);
      });
    },
    _optionsReducer: function _optionsReducer(acc, option) {
      if (option.label === 'tag') {
        acc.tags.push(option.value);
      } else {
        acc.term = option.value;
      }

      return acc;
    }
  });

  _exports.default = _default;
});