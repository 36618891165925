define("@weareevermore/satellite/components/error-page/generic", ["exports", "@weareevermore/satellite/templates/components/error-page/generic"], function (_exports, _generic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _generic.default,
    'data-autoid': 'error-page-generic'
  });

  _exports.default = _default;
});