define("@weareevermore/shuttle/mixins/jb2-adapter", ["exports", "@weareevermore/wjc-auth/mixins/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_adapter.default, {
    host: 'https://gum.worldjewishcongress.org',
    namespace: 'api/jb2'
  });

  _exports.default = _default;
});