define("@weareevermore/satellite/components/textarea-field", ["exports", "@weareevermore/satellite/mixins/base-input", "@weareevermore/satellite/templates/components/textarea-field"], function (_exports, _baseInput, _textareaField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseInput.default, {
    layout: _textareaField.default,
    rows: 3,
    lineHeight: 24,
    autoExpand: true,
    // eslint-disable-next-line ember/no-observers
    observeValue: Ember.observer('value', 'autoExpand', function () {
      this._resolveRows();
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      this._textarea = this.element.querySelector('textarea');
      var savedValue = this._textarea.value;
      this._textarea.value = '';
      this._base = this._textarea.scrollHeight;
      this._textarea.value = savedValue;

      this._resolveRows();

      this._resizeHandler = Ember.run.bind(this, this._resolveRows);
      window.addEventListener('resize', this._resizeHandler, {
        passive: true
      });
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('resize', this._resizeHandler);
    },
    _resolveRows: function _resolveRows() {
      if (!this.get('autoExpand')) {
        return;
      }

      var minRows = parseInt(this.get('rows'), 10);
      var lineHeight = parseInt(this.get('lineHeight'), 10);
      this._textarea.rows = minRows;
      this._textarea.rows = minRows + Math.ceil((this._textarea.scrollHeight - this._base) / lineHeight);
    }
  });

  _exports.default = _default;
});