define("@weareevermore/shuttle/templates/components/shuttle-person/membership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "le6sgGCB",
    "block": "{\"symbols\":[\"headquarter\"],\"statements\":[[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"headquarter\"]],[[\"@label\"],[\"Location\"]],{\"statements\":[[0,\"\\n\"],[4,\"satellite-promise\",[[24,[\"membership\",\"headquarter\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"location\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,1,[\"location\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-field-value-empty\"],[8],[0,\"Empty\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"position\"]],[[\"@label\",\"@value\"],[\"Position\",[24,[\"membership\",\"position\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"department\"]],[[\"@label\",\"@value\"],[\"Department\",[24,[\"membership\",\"department\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"in-office-since\"]],[[\"@label\",\"@value\"],[\"In Office Since\",[22,\"inOfficeSince\"]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"in-office-until\"]],[[\"@label\",\"@value\"],[\"In Office Until\",[22,\"inOfficeUntil\"]]]],[0,\"\\n\"],[5,\"shuttle-preview/contacts-field\",[],[[\"@label\",\"@value\"],[\"Direct Contacts\",[24,[\"membership\",\"contacts\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person/membership.hbs"
    }
  });

  _exports.default = _default;
});