define("@weareevermore/shuttle/templates/components/shuttle-preview/contact-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TRJ6kxYE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"shuttle-icon shuttle-icon-\",[23,0,[\"icon\"]],\" shuttle-preview-contact-icon\"]]],[8],[9],[0,\"\\n\"],[5,\"select-field\",[[12,\"class\",\"shuttle-preview-contact-form-label\"],[12,\"data-autoid\",\"contact-label\"]],[[\"@selected\",\"@collection\",\"@disabled\"],[[24,[\"contact\",\"attrs\",\"label\"]],[22,\"contactLabels\"],[22,\"areFieldsDisabled\"]]]],[0,\"\\n\\n\"],[5,\"input-field\",[[12,\"class\",\"shuttle-preview-contact-form-value\"],[12,\"data-autoid\",\"contact-value\"]],[[\"@value\",\"@disabled\"],[[24,[\"contact\",\"attrs\",\"value\"]],[22,\"areFieldsDisabled\"]]]],[0,\"\\n\\n\"],[5,\"checkbox\",[[12,\"class\",\"shuttle-preview-contact-form-primary\"],[12,\"data-autoid\",\"contact-primary\"]],[[\"@checked\",\"@label\",\"@disabled\"],[[24,[\"contact\",\"attrs\",\"isPrimary\"]],\"Primary?\",[22,\"areFieldsDisabled\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"contact\",\"isDeleted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"disabled\",[22,\"disabled\"]],[12,\"class\",\"circle-btn circle-btn-blank shuttle-preview-contact-form-btn animate\"],[12,\"data-autoid\",\"cancel-delete-contact\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"onDelete\"]],false]],[8],[0,\"\\n    \"],[1,[28,\"icon\",[\"close\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"disabled\",[22,\"disabled\"]],[12,\"class\",\"circle-btn circle-btn-danger circle-btn-blank shuttle-preview-contact-form-btn animate\"],[12,\"data-autoid\",\"delete-contact\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"onDelete\"]],true]],[8],[0,\"\\n    \"],[1,[28,\"icon\",[\"delete\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/contact-form.hbs"
    }
  });

  _exports.default = _default;
});