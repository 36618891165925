define("@weareevermore/shuttle/statics/debounce-wait-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-ember-testing-in-module-scope
  var _default = Ember.testing ? 0 : 50;

  _exports.default = _default;
});