define("@weareevermore/wjc-auth/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loginURL = loginURL;

  function loginURL(ENV) {
    if (typeof window === 'undefined') {
      return;
    }

    return [ENV.AUTH_LOGIN_URL, '?callback=', window.location.protocol, '//', window.location.host, ENV.AUTH_CALLBACK_PATH].join('');
  }
});