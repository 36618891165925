define("@weareevermore/shuttle/components/shuttle-preview/contacts-field", ["exports", "@weareevermore/shuttle/templates/components/shuttle-preview/contacts-field"], function (_exports, _contactsField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _contactsField.default
  });

  _exports.default = _default;
});