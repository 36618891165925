define("@weareevermore/shuttle/templates/components/shuttle-person/basic-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gr1JDJbW",
    "block": "{\"symbols\":[\"pointPerson\"],\"statements\":[[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"gender\"]],[[\"@label\",\"@value\"],[\"Gender\",[24,[\"person\",\"gender\"]]]]],[0,\"\\n\"],[5,\"shuttle-preview/field\",[[12,\"data-autoid\",\"point-person\"]],[[\"@label\"],[\"Point Person\"]],{\"statements\":[[0,\"\\n\"],[4,\"satellite-promise\",[[24,[\"person\",\"pointPerson\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[5,\"shuttle-person/link\",[],[[\"@person\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-field-value-empty\"],[8],[0,\"Empty\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"],[5,\"shuttle-preview/tags-field\",[[12,\"data-autoid\",\"tags\"]],[[\"@label\",\"@value\"],[\"Tags\",[24,[\"person\",\"tags\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person/basic-info.hbs"
    }
  });

  _exports.default = _default;
});