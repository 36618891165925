define("@weareevermore/shuttle/components/shuttle-slot", ["exports", "@weareevermore/shuttle/templates/components/shuttle-slot"], function (_exports, _shuttleSlot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    layout: _shuttleSlot.default,
    store: Ember.inject.service('store'),
    widget: Ember.inject.service('shuttle-widget'),
    notify: Ember.inject.service('notify'),
    dialog: Ember.inject.service('dialog'),
    attributeBindings: ['type:data-type'],
    classNames: ['shuttle-slot'],
    isFormVisible: false,
    additionalTags: null,
    dataPromise: Ember.computed('type', 'tag', 'additionalTags', function () {
      var _this = this;

      return this._loadRecords().then(function (data) {
        // TODO: do not introduce side effects here
        // eslint-disable-next-line ember/no-side-effects
        _this.set('data', Ember.A(data.toArray()));
      });
    }),
    tagModel: Ember.computed('tag', function () {
      return this._queryTags(this.get('tag')).then(function (tags) {
        return tags.get('firstObject');
      });
    }),
    allTagModels: Ember.computed('tag', 'additionalTags', function () {
      return this._queryTags(this._tagsFilter());
    }),
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(['people', 'institutions'].indexOf(this.get('type')) > -1) && Ember.assert('The type attribute must be set to "people" or "institutions"', ['people', 'institutions'].indexOf(this.get('type')) > -1));
      (false && !(!Ember.isEmpty(this.get('tag'))) && Ember.assert('The tag attribute must be present', !Ember.isEmpty(this.get('tag'))));
    },
    actions: {
      addRecord: function addRecord(record) {
        var _this2 = this;

        if (this.get('data').indexOf(record) > -1) {
          return Ember.RSVP.resolve();
        }

        return Ember.RSVP.all([this.get('allTagModels'), record.get('tags')]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 1),
              tags = _ref2[0];

          record.get('tags').addObjects(tags);
          return record.save();
        }).then(function () {
          _this2.get('notify').success('Record was added to the slot successfully.');

          _this2.set('isFormVisible', false);

          _this2.get('data').addObject(record);
        });
      },
      removeRecord: function removeRecord(record) {
        var _this3 = this;

        return Ember.RSVP.all([this.get('tagModel'), record.get('tags'), this.get('dialog').confirmDelete()]).then(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 1),
              tag = _ref4[0];

          record.get('tags').removeObject(tag);
          return record.save();
        }).then(function () {
          _this3.get('notify').success('Record was removed from the slot successfully.');

          _this3.get('data').removeObject(record);
        });
      },
      toggleForm: function toggleForm() {
        this.toggleProperty('isFormVisible');
      },
      openWidget: function openWidget(id) {
        if (this.get('type') === 'people') {
          this.get('widget').open('person', {
            personId: id
          });
        } else {
          this.get('widget').open('institution', {
            institutionId: id
          });
        }
      }
    },
    _loadRecords: function _loadRecords() {
      return this.get('store').query(this._modelName(), {
        sort: this._sortParam(),
        filter: {
          tag: this._tagsFilter()
        }
      });
    },
    _modelName: function _modelName() {
      return this.get('type') === 'people' ? 'shuttle-person' : 'shuttle-institution';
    },
    _sortParam: function _sortParam() {
      return this.get('type') === 'people' ? 'first-name,last-name' : 'name';
    },
    _tagsFilter: function _tagsFilter() {
      return Ember.A([this.get('tag'), this.get('additionalTags')]).compact().join(',');
    },
    _queryTags: function _queryTags(nameFilter) {
      return this.get('store').query('shuttle-tag', {
        filter: {
          name: nameFilter
        }
      });
    }
  });

  _exports.default = _default;
});