define("@weareevermore/satellite/templates/components/error-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TS5t7sjL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"is404\"]]],null,{\"statements\":[[0,\"  \"],[5,\"error-page/404\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"is400\"]]],null,{\"statements\":[[0,\"  \"],[5,\"error-page/400\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"is500\"]]],null,{\"statements\":[[0,\"  \"],[5,\"error-page/500\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"error-page/generic\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[5,\"error-page/footer\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/error-page.hbs"
    }
  });

  _exports.default = _default;
});