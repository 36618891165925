define("@weareevermore/shuttle/templates/components/shuttle-preview/accordion-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g6DdWm8S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onClick\"]]],null]],[11,\"class\",[29,[\"shuttle-preview-accordion-header flex-row flex-center animate \",[22,\"headerClass\"]]]],[10,\"data-autoid\",\"accordion-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-auto\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"shuttle-preview-accordion-header-primary flex-row flex-center\"],[10,\"data-autoid\",\"accordion-title\"],[8],[0,\"\\n      \"],[1,[22,\"title\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"isPrimary\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-accordion-is-primary animate\"],[10,\"data-autoid\",\"accordion-is-primary\"],[8],[0,\"primary\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-accordion-header-secondary\"],[10,\"data-autoid\",\"accordion-subtitle\"],[8],[0,\"\\n      \"],[1,[22,\"subtitle\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"onLinkClick\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[11,\"onclick\",[22,\"onLinkClick\"]],[10,\"class\",\"shuttle-preview-accordion-header-btn circle-btn circle-btn-blank\"],[10,\"data-autoid\",\"accordion-header-btn\"],[8],[0,\"\\n      \"],[1,[28,\"icon\",[\"launch\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"icon shuttle-preview-accordion-header-icon\"],[8],[1,[23,0,[\"icon\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isOpened\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"shuttle-preview-accordion-body\"],[10,\"data-autoid\",\"accordion-body\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/accordion-section.hbs"
    }
  });

  _exports.default = _default;
});