define("@weareevermore/wjc-auth/services/session", ["exports", "@weareevermore/wjc-auth/store", "@weareevermore/wjc-auth/base64", "@weareevermore/wjc-auth/utils"], function (_exports, _store, _base, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    authAjax: Ember.inject.service('auth-ajax'),
    refreshURL: null,
    // Set by the initializer
    isAuthenticated: false,
    token: null,
    user: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._setupStore();

      this._setupURLs();

      this._setupRefreshInterval();

      this._bindStoreEvents();
    },
    authenticate: function authenticate(token) {
      var isAuthenticated = this.get('isAuthenticated');

      var json = this._parseToken(token);

      if (this._validateTokenJSON(json)) {
        this.setProperties({
          isAuthenticated: true,
          token: token,
          user: Ember.Object.create(json.user)
        });
        this.store.persist('token', token);

        if (!isAuthenticated) {
          this.trigger('sessionAuthenticated');
        }
      } else {
        this.invalidate();
      }
    },
    invalidate: function invalidate() {
      var isAuthenticated = this.get('isAuthenticated');
      this.setProperties({
        isAuthenticated: false,
        token: null
      });
      this.store.clear();

      if (isAuthenticated) {
        this.trigger('sessionInvalidated');
      }
    },
    restore: function restore() {
      var token = this.store.retrieve('token');

      if (Ember.isEmpty(token)) {
        this.invalidate();
      } else {
        this.authenticate(token);
      }
    },
    requestHeaders: function requestHeaders() {
      if (this.get('isAuthenticated')) {
        return {
          Authorization: "Bearer ".concat(this.get('token'))
        };
      } else {
        return {};
      }
    },
    _parseToken: function _parseToken(token) {
      try {
        var segment = token.split('.')[1];

        var decoded = _base.default.decode(segment);

        return JSON.parse(decoded);
      } catch (error) {
        return false;
      }
    },
    _validateTokenJSON: function _validateTokenJSON(json) {
      return json && json.exp * 1000 - new Date().getTime() > 0;
    },
    _refreshToken: function _refreshToken() {
      var _this = this;

      var headers = this.requestHeaders();
      return this.get('authAjax').put(this.refreshURL, {
        headers: headers
      }).then(function (_ref) {
        var token = _ref.token;
        return _this.authenticate(token);
      }).catch(function () {
        return _this.invalidate();
      });
    },
    _setupStore: function _setupStore() {
      this.store = Ember.testing ? _store.InMemoryStore.create() : _store.Store.create();
    },
    _setupURLs: function _setupURLs() {
      var ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      this.setProperties({
        loginURL: (0, _utils.loginURL)(ENV),
        logoutURL: ENV.AUTH_LOGOUT_URL,
        refreshURL: ENV.AUTH_REFRESH_URL
      });
    },
    _setupRefreshInterval: function _setupRefreshInterval() {
      if (Ember.testing) {
        return;
      }

      var callback = Ember.run.bind(this, this._refreshToken);
      var interval = 10 * 60 * 1000; // 10 minutes

      setInterval(callback, interval);
    },
    _bindStoreEvents: function _bindStoreEvents() {
      var _this2 = this;

      this.store.on('storeDataUpdated', function () {
        _this2.restore();
      });
    }
  });

  _exports.default = _default;
});