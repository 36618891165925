define("@weareevermore/shuttle/templates/components/shuttle-person/relation-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F8G8imWu",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[5,\"power-select-field\",[[12,\"data-autoid\",\"type\"]],[[\"@selected\",\"@label\",\"@disabled\"],[[22,\"type\"],\"Type\",[22,\"disabled\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"power-select\",[],[[\"@selected\",\"@options\",\"@onChange\",\"@searchEnabled\",\"@disabled\"],[[22,\"type\"],[22,\"types\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"type\"]]],null]],null],true,[22,\"disabled\"]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"shuttle-select\",[[12,\"data-autoid\",\"person\"]],[[\"@type\",\"@selected\",\"@label\",\"@onChange\",\"@disabled\"],[\"person\",[22,\"relatedTo\"],\"Person\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"relatedTo\"]]],null]],null],[22,\"disabled\"]]]],[0,\"\\n\\n\"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"save\"],null]],[10,\"class\",\"btn btn-primary\"],[11,\"disabled\",[22,\"disabled\"]],[10,\"data-autoid\",\"save\"],[8],[0,\"\\n  \"],[1,[28,\"icon\",[\"done\"],null],false],[0,\" Save\\n\"],[9],[0,\"\\n\"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onCancel\"]]],null]],[10,\"class\",\"btn btn-secondary\"],[11,\"disabled\",[22,\"disabled\"]],[10,\"data-autoid\",\"cancel\"],[8],[0,\"\\n  \"],[1,[28,\"icon\",[\"close\"],null],false],[0,\" Cancel\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person/relation-form.hbs"
    }
  });

  _exports.default = _default;
});