define("@weareevermore/shuttle/templates/components/shuttle-list/search-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZ9SRvbR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"shuttle-list-search-label -\",[24,[\"option\",\"label\"]]]]],[8],[1,[24,[\"option\",\"label\"]],false],[9],[7,\"span\",true],[10,\"class\",\"shuttle-list-search-value\"],[8],[1,[24,[\"option\",\"value\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-list/search-selected.hbs"
    }
  });

  _exports.default = _default;
});