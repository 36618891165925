define("ember-concurrency-decorators/last-value", ["exports", "@ember-decorators/utils/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This decorator allows you to alias a property to the result of a task. You can also provide a default value to use before the task has completed.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency-decorators';
   * import { lastValue } from 'ember-concurrency-decorators';
   *
   * export default class ExampleComponent extends Component {
   *   @task
   *   someTask = function*() {
   *     // ...
   *   };
   *
   *   @lastValue('someTask')
   *   someTaskValue;
   *
   *   @lastValue('someTask')
   *   someTaskValueWithDefault = 'A default value';
   * }
   * ```
   *
   * @function
   * @param {string} taskName the name of the task to read a value from
   */
  var _default = (0, _decorator.decoratorWithRequiredParams)(function lastValue(target, key, desc, _ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        taskName = _ref2[0];

    (false && !(typeof taskName === 'string') && Ember.assert("ember-concurrency-decorators: @lastValue expects a task name as the first parameter.", typeof taskName === 'string'));
    var initializer = desc.initializer;
    delete desc.initializer;
    var cp = Ember.computed("".concat(taskName, ".lastSuccessful"), function () {
      var lastInstance = Ember.get(this, "".concat(taskName, ".lastSuccessful"));

      if (lastInstance) {
        return Ember.get(lastInstance, 'value');
      }

      if (initializer) {
        return initializer.call(this);
      }
    }); // @ts-ignore

    return cp(target, key, desc);
  });

  _exports.default = _default;
});