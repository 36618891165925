define("@weareevermore/wjc-auth/routes/auth-callback", ["exports", "@weareevermore/wjc-auth/mixins/unauthenticated-route", "@weareevermore/wjc-auth/base64"], function (_exports, _unauthenticatedRoute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRoute.default, {
    session: Ember.inject.service('session'),
    _skipAuthentication: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.get('session').authenticate(controller.get('token'));

      if (this.get('session.isAuthenticated') && controller.get('to')) {
        var to = _base.default.decode(controller.get('to'));

        this.transitionTo(to);
      }
    }
  });

  _exports.default = _default;
});