define("@weareevermore/shuttle/templates/components/shuttle-preview/accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SQOBOMfY",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"collection\"]]],[[\"key\"],[[24,[\"key\"]]]],{\"statements\":[[0,\"  \"],[5,\"shuttle-preview/accordion-section\",[[12,\"data-autoid\",\"accordion-section\"]],[[\"@item\",\"@title\",\"@subtitle\",\"@isPrimary\",\"@currentIndex\",\"@defaultActiveIndex\",\"@index\",\"@onClick\",\"@onLinkClick\"],[[23,1,[]],[28,\"get\",[[23,1,[]],[24,[\"titlePath\"]]],null],[28,\"get\",[[23,1,[]],[24,[\"subtitlePath\"]]],null],[28,\"get\",[[23,1,[]],[24,[\"isPrimaryPath\"]]],null],[22,\"currentIndex\"],[22,\"defaultActiveIndex\"],[23,2,[]],[28,\"action\",[[23,0,[]],\"expandSection\",[23,2,[]]],null],[28,\"if\",[[24,[\"onLinkClick\"]],[28,\"action\",[[23,0,[]],\"handleOnLinkClick\",[23,1,[]]],null],null],null]]],{\"statements\":[[0,\"\\n    \"],[14,3,[[23,1,[]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/accordion.hbs"
    }
  });

  _exports.default = _default;
});