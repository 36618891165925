define("@weareevermore/satellite/templates/components/redactor-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5ZLxXFcc",
    "block": "{\"symbols\":[],\"statements\":[[5,\"textarea-field\",[[12,\"placeholder\",[22,\"placeholder\"]]],[[\"@value\",\"@disabled\",\"@autofocus\"],[[22,\"value\"],[22,\"disabled\"],[22,\"autofocus\"]]]],[0,\"\\n\\n\"],[7,\"label\",true],[10,\"class\",\"field-label animate\"],[8],[1,[22,\"resolvedLabel\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"field-errors\"],[8],[1,[22,\"errorMessages\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/redactor-field.hbs"
    }
  });

  _exports.default = _default;
});