define("@weareevermore/shuttle/models/shuttle-list", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    listType: (0, _attr.default)('string', {
      default: 'contact'
    }),
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    conditions: (0, _attr.default)('string', {
      default: 'and'
    }),
    tags: (0, _relationships.hasMany)('shuttle-tag')
  });

  _exports.default = _default;
});