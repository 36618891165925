define("@weareevermore/satellite/components/dialog", ["exports", "@weareevermore/satellite/templates/components/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dialog.default,
    dialog: Ember.inject.service('dialog'),
    message: Ember.computed.readOnly('dialog.message'),
    hasMessage: Ember.computed('message', {
      get: function get() {
        var hasMessage = !Ember.isEmpty(this.get('message'));
        var callback = hasMessage ? this._attachDocumentEventHandler : this._detachDocumentEventHandler;
        Ember.run.scheduleOnce('afterRender', this, callback);
        return hasMessage;
      }
    }),
    displayCancelButton: Ember.computed('message.cancelLabel', function () {
      return this.get('message.cancelLabel') !== false;
    }),
    willDestroyElement: function willDestroyElement() {
      this._detachDocumentEventHandler();
    },
    actions: {
      confirm: function confirm() {
        this.dialog.onConfirm();
      },
      cancel: function cancel() {
        this.dialog.onCancel();
      }
    },
    _detachDocumentEventHandler: function _detachDocumentEventHandler() {
      document.removeEventListener('keydown', this._keyDownHandler);
    },
    _attachDocumentEventHandler: function _attachDocumentEventHandler() {
      var _this = this;

      this._keyDownHandler = function (e) {
        e.preventDefault();

        if (e.keyCode === 27) {
          Ember.run(function () {
            return _this.send('cancel');
          });
        }

        if (e.keyCode === 13) {
          Ember.run(function () {
            return _this.send('confirm');
          });
        }
      };

      document.addEventListener('keydown', this._keyDownHandler);
    }
  });

  _exports.default = _default;
});