define("@weareevermore/shuttle/helpers/find-index-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findIndexOf = findIndexOf;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function findIndexOf(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        collection = _ref2[0],
        property = _ref2[1],
        value = _ref2[2];

    var result = null;
    Ember.A(collection).find(function (element, index) {
      if (element.get(property) === value) {
        result = index;
        return true;
      } else {
        return false;
      }
    });
    return result;
  }

  var _default = Ember.Helper.helper(findIndexOf);

  _exports.default = _default;
});