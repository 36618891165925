define("@weareevermore/satellite/templates/components/input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v628DGEZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\",false],[12,\"value\",[22,\"value\"]],[12,\"autofocus\",[22,\"autofocus\"]],[12,\"disabled\",[22,\"disabled\"]],[12,\"placeholder\",[22,\"placeholder\"]],[12,\"required\",[22,\"required\"]],[12,\"type\",[22,\"type\"]],[3,\"on\",[\"input\",[28,\"action\",[[23,0,[]],\"onChange\"],null]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"label\",true],[10,\"class\",\"field-label animate\"],[8],[1,[22,\"resolvedLabel\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"field-errors\"],[8],[1,[22,\"errorMessages\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/input-field.hbs"
    }
  });

  _exports.default = _default;
});