define("@weareevermore/satellite/templates/components/fieldset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tLCyfxeE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"legend\"]]],null,{\"statements\":[[0,\"  \"],[7,\"legend\",true],[10,\"class\",\"fieldset-legend\"],[8],[0,\"\\n    \"],[1,[22,\"legend\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/fieldset.hbs"
    }
  });

  _exports.default = _default;
});