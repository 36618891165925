define("@weareevermore/satellite/templates/components/select-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f2v0vsmA",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\",false],[12,\"disabled\",[22,\"disabled\"]],[12,\"required\",[22,\"required\"]],[3,\"action\",[[23,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"allowBlank\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[10,\"value\",\"\"],[11,\"selected\",[28,\"not\",[[24,[\"selected\"]]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"collection\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[11,\"value\",[29,[[23,1,[\"value\"]]]]],[11,\"selected\",[28,\"eq\",[[24,[\"selected\"]],[23,1,[\"value\"]]],null]],[11,\"disabled\",[23,1,[\"disabled\"]]],[8],[0,\"\\n      \"],[1,[23,1,[\"label\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[7,\"label\",true],[10,\"class\",\"field-label animate\"],[8],[1,[22,\"resolvedLabel\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"field-errors\"],[8],[1,[22,\"errorMessages\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"icon field-select-icon\"],[8],[0,\"keyboard_arrow_down\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/select-field.hbs"
    }
  });

  _exports.default = _default;
});