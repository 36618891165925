define("@weareevermore/shuttle/components/shuttle-institution/basic-info", ["exports", "@weareevermore/shuttle/templates/components/shuttle-institution/basic-info"], function (_exports, _basicInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _basicInfo.default
  });

  _exports.default = _default;
});