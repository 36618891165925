define("@weareevermore/shuttle/components/shuttle-person/membership", ["exports", "@weareevermore/shuttle/templates/components/shuttle-person/membership", "moment"], function (_exports, _membership, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _membership.default,
    dateFormat: 'DD MMMM YYYY',
    inOfficeSince: Ember.computed('membership.inOfficeSince', function () {
      var date = this.get('membership.inOfficeSince');
      return Ember.isEmpty(date) ? null : (0, _moment.default)(date).format(this.get('dateFormat'));
    }),
    inOfficeUntil: Ember.computed('membership.inOfficeUntil', function () {
      var date = this.get('membership.inOfficeUntil');
      return Ember.isEmpty(date) ? null : (0, _moment.default)(date).format(this.get('dateFormat'));
    })
  });

  _exports.default = _default;
});