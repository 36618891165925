define("@weareevermore/shuttle/components/shuttle-list/empty", ["exports", "@weareevermore/shuttle/templates/components/shuttle-list/empty"], function (_exports, _empty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _empty.default,
    classNames: ['shuttle-list-empty'],
    hasQuery: Ember.computed('tags.[]', 'term', function () {
      return !Ember.isEmpty(this.get('tags')) || !Ember.isEmpty(this.get('term'));
    }),
    currentTags: Ember.computed('tags.[]', function () {
      return this.get('tags').join(', ');
    })
  });

  _exports.default = _default;
});