define("@weareevermore/shuttle/helpers/shuttle-full-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    authorize: Ember.inject.service('shuttle-full-access'),
    compute: function compute() {
      return this.get('authorize').hasFullAccess();
    }
  });

  _exports.default = _default;
});