define("@weareevermore/shuttle/components/shuttle-preview/contacts-form", ["exports", "@weareevermore/shuttle/templates/components/shuttle-preview/contacts-form", "@weareevermore/shuttle/statics/contact-labels"], function (_exports, _contactsForm, _contactLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _contactsForm.default,
    init: function init() {
      this._super.apply(this, arguments);

      var contacts = this.get('contacts').map(function (contact) {
        return Ember.Object.create({
          id: contact.get('id'),
          attrs: contact.getProperties('isPrimary', 'value', 'label')
        });
      });
      this.set('contacts', Ember.A(contacts));
    },
    actions: {
      save: function save() {
        this.set('isSaving', true);
        this.onSave(this.get('contacts')).then(this.onCancel);
      },
      addContact: function addContact() {
        var contact = Ember.Object.create({
          attrs: {
            isPrimary: false,
            label: _contactLabels.default[0].value
          },
          isNew: true
        });
        this.get('contacts').addObject(contact);
      },
      handleDelete: function handleDelete(contact, value) {
        if (contact.get('isNew')) {
          this.get('contacts').removeObject(contact);
        } else {
          contact.set('isDeleted', value);
        }
      }
    }
  });

  _exports.default = _default;
});