define("@weareevermore/shuttle/templates/components/shuttle-preview/contacts-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2z9pI4WJ",
    "block": "{\"symbols\":[\"contact\"],\"statements\":[[4,\"each\",[[24,[\"contacts\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shuttle-preview/contact-form\",[[12,\"data-autoid\",\"contact-form\"]],[[\"@contact\",\"@disabled\",\"@onDelete\"],[[23,1,[]],[22,\"isSaving\"],[28,\"action\",[[23,0,[]],\"handleDelete\",[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-secondary\"],[12,\"disabled\",[22,\"isSaving\"]],[12,\"data-autoid\",\"add-contact\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"addContact\"]],[8],[0,\"\\n  \"],[1,[28,\"icon\",[\"add\"],null],false],[0,\" Add Contact\\n\"],[9],[0,\"\\n\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"disabled\",[22,\"isSaving\"]],[12,\"data-autoid\",\"save\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n  \"],[1,[28,\"icon\",[\"done\"],null],false],[0,\" Save\\n\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"disabled\",[22,\"isSaving\"]],[12,\"data-autoid\",\"cancel\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"onCancel\"]]]],[8],[0,\"\\n  \"],[1,[28,\"icon\",[\"close\"],null],false],[0,\" Cancel\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/contacts-form.hbs"
    }
  });

  _exports.default = _default;
});