define("@weareevermore/satellite/templates/components/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XN5HkU/N",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"InputField\",\"DatepickerField\",\"TextareaField\",\"RedactorField\",\"SelectField\",\"PowerSelectField\",\"RadioButtons\",\"Checkbox\",\"Submit\"],[[28,\"component\",[\"input-field\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"datepicker-field\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"textarea-field\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"redactor-field\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"select-field\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"power-select-field\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"radio-buttons\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"checkbox\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]],[28,\"component\",[\"submit\"],[[\"formModel\",\"formErrors\",\"disabled\"],[[24,[\"model\"]],[24,[\"errors\"]],[28,\"readonly\",[[24,[\"disabled\"]]],null]]]]]]]]],[0,\"\\n\"],[7,\"input\",true],[10,\"style\",\"display: none;\"],[10,\"type\",\"submit\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/form.hbs"
    }
  });

  _exports.default = _default;
});