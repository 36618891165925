define("@weareevermore/shuttle/components/shuttle-list/person", ["exports", "@weareevermore/shuttle/templates/components/shuttle-list/person"], function (_exports, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _person.default,
    classNames: ['shuttle-list-item', 'animate']
  });

  _exports.default = _default;
});