define("@weareevermore/shuttle/components/shuttle-preview/edit-mode", ["exports", "@weareevermore/shuttle/templates/components/shuttle-preview/edit-mode"], function (_exports, _editMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _editMode.default,
    actions: {
      setEditMode: function setEditMode(value) {
        this.set('editMode', value);

        if (this.get('editMode') && this.onEdit) {
          this.onEdit();
        } else if (!this.get('editMode') && this.onPreview) {
          this.onPreview();
        }
      }
    }
  });

  _exports.default = _default;
});