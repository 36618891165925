define("@weareevermore/shuttle/services/shuttle-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    createRecord: function createRecord(attrs) {
      return this.get('store').createRecord('shuttle-contact', attrs).save();
    },
    updateRecord: function updateRecord(id, attrs) {
      var record = this._peekRecord(id);

      record.setProperties(attrs);
      return record.hasDirtyAttributes ? record.save() : Ember.RSVP.resolve(record);
    },
    destroyRecord: function destroyRecord(id) {
      return this._peekRecord(id).destroyRecord();
    },
    saveAll: function saveAll(contacts, parent) {
      var _this = this;

      contacts = contacts.map(function (contact) {
        return _this._save(contact, parent);
      });
      return Ember.RSVP.all(contacts);
    },
    _peekRecord: function _peekRecord(id) {
      return this.store.peekRecord('shuttle-contact', id);
    },
    _save: function _save(_ref, parent) {
      var id = _ref.id,
          attrs = _ref.attrs,
          isNew = _ref.isNew,
          isDeleted = _ref.isDeleted;

      if (isNew) {
        return this.createRecord(_objectSpread({}, attrs, {}, parent));
      } else if (isDeleted) {
        return this.destroyRecord(id);
      } else {
        return this.updateRecord(id, attrs);
      }
    }
  });

  _exports.default = _default;
});