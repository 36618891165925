define("@weareevermore/shuttle/components/shuttle-person/new", ["exports", "@weareevermore/shuttle/templates/components/shuttle-person/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _new.default,
    store: Ember.inject.service('store'),
    notify: Ember.inject.service('notify'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('person', Ember.Object.create({
        tags: Ember.A([])
      }));
    },
    actions: {
      save: function save(attrs) {
        var onCreate = this.onCreate;
        var notify = this.get('notify');
        var person = this.get('store').createRecord('shuttle-person');
        person.setProperties(attrs);
        return person.save().then(function (person) {
          notify.success('Person was created successfully.');
          onCreate(person.id);
        });
      }
    }
  });

  _exports.default = _default;
});