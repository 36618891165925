define("@weareevermore/wjc-auth/store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.InMemoryStore = _exports.Store = void 0;
  var Store = Ember.Object.extend(Ember.Evented, {
    base: 'wjc-auth',
    _data: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.bindStorageEvents();
    },
    persist: function persist(key, value) {
      this._data[key] = value;
      localStorage.setItem("".concat(this.base, ":").concat(key), value);
    },
    retrieve: function retrieve(key) {
      return localStorage.getItem("".concat(this.base, ":").concat(key));
    },
    clear: function clear() {
      var _this = this;

      this._data = {};
      Object.keys(localStorage).forEach(function (key) {
        if (key.indexOf(_this.base) === 0) {
          localStorage.removeItem(key);
        }
      });
    },
    bindStorageEvents: function bindStorageEvents() {
      var _this2 = this;

      window.addEventListener('storage', function () {
        var token = _this2.retrieve('token');

        if (token !== _this2._data.token) {
          _this2._data.token = token;

          _this2.trigger('storeDataUpdated');
        }
      });
    }
  });
  _exports.Store = Store;
  var InMemoryStore = Ember.Object.extend(Ember.Evented, {
    _data: {},
    init: function init() {
      this._data = {};
    },
    persist: function persist(key, value) {
      this._data[key] = value;
    },
    retrieve: function retrieve(key) {
      return this._data[key];
    },
    clear: function clear() {
      this._data = {};
    }
  });
  _exports.InMemoryStore = InMemoryStore;
  var _default = Store;
  _exports.default = _default;
});