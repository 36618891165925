define("@weareevermore/satellite/components/scrollbar", ["exports", "perfect-scrollbar", "@weareevermore/satellite/utils/is-touch-device", "@weareevermore/satellite/templates/components/scrollbar"], function (_exports, _perfectScrollbar, _isTouchDevice, _scrollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _scrollbar.default,
    classNameBindings: [':scrollbar-wrapper', 'modifierClass', '_onStart:start', '_onEnd:end'],
    wheelSpeed: 1,
    wheelPropagation: false,
    swipePropagation: true,
    minScrollbarLength: null,
    maxScrollbarLength: null,
    useBothWheelAxes: false,
    useKeyboard: true,
    suppressScrollX: true,
    suppressScrollY: false,
    scrollTop: 0,
    scrollLeft: 0,
    scrollXMarginOffset: 0,
    scrollYMarginOffset: 0,
    includePadding: false,
    _onStart: true,
    _onEnd: true,

    get _node() {
      return this.element.firstElementChild;
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.set('modifierClass', _isTouchDevice.default ? '-native' : '-custom');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this._initScrollbar);
    },
    willDestroyElement: function willDestroyElement() {
      this._destroyScrollbar();

      this._super.apply(this, arguments);
    },
    _initScrollbar: function _initScrollbar() {
      var _this = this;

      Ember.run(function () {
        _this._attachUpdateEvents();

        if (_isTouchDevice.default) {
          return;
        }

        _this._perfectScrollbar = new _perfectScrollbar.default(_this._node, _this._getOptions());

        _this._setInitialOverlay();

        _this._attachOverlayEvents();
      });
    },
    _destroyScrollbar: function _destroyScrollbar() {
      var _this2 = this;

      Ember.run(function () {
        _this2._detachUpdateEvents();

        if (_isTouchDevice.default) {
          return;
        }

        _this2._perfectScrollbar.destroy();

        _this2._detachOverlayEvents();
      });
    },
    _updateScrollbar: function _updateScrollbar(position) {
      var _this3 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (position === 'bottom') {
        this._node.scrollTop = this._node.scrollHeight + 10;
      } else if (!Ember.isEmpty(position)) {
        this._node.scrollTop = position;
      }

      if (_isTouchDevice.default) {
        return;
      }

      Ember.run(function () {
        _this3._perfectScrollbar.update();

        _this3._setInitialOverlay();
      });

      if (position === 'bottom') {
        this.set('_onEnd', true);
      }
    },
    _setInitialOverlay: function _setInitialOverlay() {
      var scrollHeight = this._node.scrollHeight;
      var height = this._node.offsetHeight;

      if (scrollHeight <= height) {
        this.setProperties({
          _onStart: true,
          _onEnd: true
        });
      } else if (scrollHeight > height) {
        this.set('_onEnd', false);
      }
    },
    _getOptions: function _getOptions() {
      return this.getProperties('wheelSpeed', 'wheelPropagation', 'swipePropagation', 'minScrollbarLength', 'maxScrollbarLength', 'useBothWheelAxes', 'useKeyboard', 'suppressScrollX', 'suppressScrollY', 'scrollXMarginOffset', 'scrollYMarginOffset', 'includePadding');
    },
    _attachUpdateEvents: function _attachUpdateEvents() {
      var _this4 = this;

      this._updateScrollHandler = function () {
        return Ember.run.scheduleOnce('afterRender', _this4, _this4._updateScrollbar);
      };

      this._resetScrollHandler = function () {
        return Ember.run.scheduleOnce('afterRender', _this4, _this4._updateScrollbar, 0);
      };

      this._scrollToBottomHandler = function () {
        return Ember.run.scheduleOnce('afterRender', _this4, _this4._updateScrollbar, 'bottom');
      };

      this.element.addEventListener('update-scroll', this._updateScrollHandler, {
        passive: true
      });
      this.element.addEventListener('reset-scroll', this._resetScrollHandler, {
        passive: true
      });
      this.element.addEventListener('scroll-to-bottom', this._scrollToBottomHandler, {
        passive: true
      });
    },
    _attachOverlayEvents: function _attachOverlayEvents() {
      var _this5 = this;

      this._scrollDownHandler = function () {
        if (_this5.get('_onStart')) {
          _this5.set('_onStart', false);
        }
      };

      this._scrollUpHandler = function () {
        if (_this5.get('_onEnd')) {
          _this5.set('_onEnd', false);
        }
      };

      this._reachEndHandler = function () {
        if (!_this5.get('_onEnd')) {
          _this5.set('_onEnd', true);
        }
      };

      this._reachStartHandler = function () {
        if (!_this5.get('_onStart')) {
          _this5.set('_onStart', true);
        }
      };

      this._node.addEventListener('ps-scroll-down', this._scrollDownHandler, {
        passive: true
      });

      this._node.addEventListener('ps-scroll-up', this._scrollUpHandler, {
        passive: true
      });

      this._node.addEventListener('ps-y-reach-end', this._reachEndHandler, {
        passive: true
      });

      this._node.addEventListener('ps-y-reach-start', this._reachStartHandler, {
        passive: true
      });
    },
    _detachUpdateEvents: function _detachUpdateEvents() {
      this.element.removeEventListener('update-scroll', this._updateScrollHandler);
      this.element.removeEventListener('reset-scroll', this._resetScrollHandler);
      this.element.removeEventListener('scroll-to-bottom', this._scrollToBottomHandler);
    },
    _detachOverlayEvents: function _detachOverlayEvents() {
      this._node.removeEventListener('ps-scroll-down', this._scrollDownHandler);

      this._node.removeEventListener('ps-scroll-up', this._scrollUpHandler);

      this._node.removeEventListener('ps-y-reach-end', this._reachEndHandler);

      this._node.removeEventListener('ps-y-reach-start', this._reachStartHandler);
    }
  });

  _exports.default = _default;
});