define("@weareevermore/shuttle/components/shuttle-list/search-selected", ["exports", "@weareevermore/shuttle/templates/components/shuttle-list/search-selected"], function (_exports, _searchSelected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _searchSelected.default,
    tagName: 'span',
    classNames: ['shuttle-list-search-selected']
  });

  _exports.default = _default;
});