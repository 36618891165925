define("@weareevermore/satellite/templates/components/notify/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tT8jmp9d",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"notify-icon\"],[8],[0,\"\\n  \"],[1,[28,\"icon\",[[24,[\"typeIcon\"]]],null],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"notify-border\"],[10,\"role\",\"presentation\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"span\",true],[8],[1,[24,[\"message\",\"text\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/notify/message.hbs"
    }
  });

  _exports.default = _default;
});