define("@weareevermore/satellite/components/select-field", ["exports", "@weareevermore/satellite/mixins/base-input", "@weareevermore/satellite/templates/components/select-field"], function (_exports, _baseInput, _selectField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseInput.default, {
    layout: _selectField.default,
    allowBlank: false,
    selected: Ember.computed.alias('value'),
    actions: {
      change: function change() {
        var newValue = null;
        var select = this.element.querySelector('select');

        if (this.get('allowBlank') && select.selectedIndex > 0) {
          newValue = Ember.get(this.get('collection')[select.selectedIndex - 1], 'value');
        } else if (!this.get('allowBlank')) {
          newValue = Ember.get(this.get('collection')[select.selectedIndex], 'value');
        }

        this.set('selected', newValue);
      }
    }
  });

  _exports.default = _default;
});