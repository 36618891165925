define("@weareevermore/shuttle/statics/titles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.A([{
    value: 'Admiral',
    label: 'Admiral'
  }, {
    value: 'Amb.',
    label: 'Amb.'
  }, {
    value: 'Archbishop',
    label: 'Archbishop'
  }, {
    value: 'Baron',
    label: 'Baron'
  }, {
    value: 'Baroness',
    label: 'Baroness'
  }, {
    value: 'Bishop',
    label: 'Bishop'
  }, {
    value: 'Brig. Gen.',
    label: 'Brig. Gen.'
  }, {
    value: 'Cardinal',
    label: 'Cardinal'
  }, {
    value: 'Chief Rabbi',
    label: 'Chief Rabbi'
  }, {
    value: 'Colonel',
    label: 'Colonel'
  }, {
    value: 'Commissioner',
    label: 'Commissioner'
  }, {
    value: 'Earl',
    label: 'Earl'
  }, {
    value: 'Fr.',
    label: 'Fr.'
  }, {
    value: 'Gen.',
    label: 'Gen.'
  }, {
    value: 'H.E.',
    label: 'H.E.'
  }, {
    value: 'H.E. Dr.',
    label: 'H.E. Dr.'
  }, {
    value: 'H.E. Mr.',
    label: 'H.E. Mr.'
  }, {
    value: 'H.E. Mrs.',
    label: 'H.E. Mrs.'
  }, {
    value: 'H.E. Ms.',
    label: 'H.E. Ms.'
  }, {
    value: 'His Holiness',
    label: 'His Holiness'
  }, {
    value: 'H.M. King',
    label: 'H.M. King'
  }, {
    value: 'H.M. Queen',
    label: 'H.M. Queen'
  }, {
    value: 'Hon.',
    label: 'Hon.'
  }, {
    value: 'H.R.H. Prince',
    label: 'H.R.H. Prince'
  }, {
    value: 'H.R.H. Princess',
    label: 'H.R.H. Princess'
  }, {
    value: 'H.S.H. Prince',
    label: 'H.S.H. Prince'
  }, {
    value: 'Imam',
    label: 'Imam'
  }, {
    value: 'Lady',
    label: 'Lady'
  }, {
    value: 'Lord',
    label: 'Lord'
  }, {
    value: 'Lt. Gen.',
    label: 'Lt. Gen.'
  }, {
    value: 'Miss',
    label: 'Miss'
  }, {
    value: 'M.K.',
    label: 'M.K.'
  }, {
    value: 'Mr.',
    label: 'Mr.'
  }, {
    value: 'Mrs.',
    label: 'Mrs.'
  }, {
    value: 'Ms.',
    label: 'Ms.'
  }, {
    value: 'Mufti',
    label: 'Mufti'
  }, {
    value: 'President',
    label: 'President'
  }, {
    value: 'Prince',
    label: 'Prince'
  }, {
    value: 'Princess',
    label: 'Princess'
  }, {
    value: 'Prof.',
    label: 'Prof.'
  }, {
    value: 'Prof. Dr.',
    label: 'Prof. Dr.'
  }, {
    value: 'Rabbi',
    label: 'Rabbi'
  }, {
    value: 'Rav',
    label: 'Rav'
  }, {
    value: 'Rebbetzin',
    label: 'Rebbetzin'
  }, {
    value: 'Rep.',
    label: 'Rep.'
  }, {
    value: 'Senator',
    label: 'Senator'
  }, {
    value: 'Sheikh',
    label: 'Sheikh'
  }, {
    value: 'Sir',
    label: 'Sir'
  }, {
    value: 'The Hon.',
    label: 'The Hon.'
  }, {
    value: 'The Rt. Hon.',
    label: 'The Rt. Hon.'
  }]);

  _exports.default = _default;
});