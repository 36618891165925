define("@weareevermore/satellite/components/error-page/500", ["exports", "@weareevermore/satellite/templates/components/error-page/500"], function (_exports, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _.default,
    'data-autoid': 'error-page-500'
  });

  _exports.default = _default;
});