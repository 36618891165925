define("@weareevermore/shuttle/templates/components/shuttle-preview/tags-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zjh3W0ok",
    "block": "{\"symbols\":[\"tags\",\"tag\"],\"statements\":[[5,\"shuttle-preview/field\",[],[[\"@label\"],[[22,\"label\"]]],{\"statements\":[[0,\"\\n\"],[4,\"satellite-promise\",[[24,[\"value\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"shuttle-tag\"],[10,\"data-autoid\",\"tag\"],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-field-value-empty\"],[8],[0,\"Empty\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-preview/tags-field.hbs"
    }
  });

  _exports.default = _default;
});