define("@weareevermore/shuttle/templates/components/shuttle-list/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A2Hy8nFW",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"shuttle-list-search-icon icon\"],[8],[0,\"search\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"shuttle-list-search-select\"],[10,\"data-autoid\",\"search-select\"],[8],[0,\"\\n  \"],[5,\"power-select-multiple\",[],[[\"@selected\",\"@allowClear\",\"@dropdownClass\",\"@loadingMessage\",\"@noMatchesMessage\",\"@onChange\",\"@placeholder\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@searchMessage\",\"@selectClass\",\"@selectedItemComponent\",\"@triggerClass\",\"@renderInPlace\"],[[22,\"selected\"],true,\"shuttle-list-search-dropdown\",\"Loading records from the database...\",\"There are no tags matching the search query\",[28,\"action\",[[23,0,[]],\"handleChange\"],null],\"Filter the list by free text or tags\",[28,\"action\",[[23,0,[]],\"search\"],null],true,\"value\",\"Start typing to see suggestions from the database\",\"shuttle-list-search-input\",\"shuttle-list/search-selected\",\"shuttle-list-search-trigger\",true]],{\"statements\":[[0,\"\\n\"],[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"shuttle-list-search-label -\",[23,1,[\"label\"]]]]],[8],[1,[23,1,[\"label\"]],false],[9],[7,\"span\",true],[10,\"class\",\"shuttle-list-search-value\"],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-list/search.hbs"
    }
  });

  _exports.default = _default;
});