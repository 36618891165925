define("@weareevermore/satellite/templates/components/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gY3YxZib",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[11,\"class\",[29,[\"field-label-checkbox animate \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[8],[0,\"\\n  \"],[7,\"input\",false],[12,\"checked\",[22,\"value\"]],[12,\"disabled\",[22,\"disabled\"]],[12,\"required\",[22,\"required\"]],[12,\"type\",\"checkbox\"],[3,\"on\",[\"change\",[28,\"action\",[[23,0,[]],\"onChange\"],null]]],[3,\"on\",[\"focus\",[28,\"fn\",[[28,\"mut\",[[24,[\"isFocused\"]]],null],\"is-focused\"],null]]],[3,\"on\",[\"blur\",[28,\"fn\",[[28,\"mut\",[[24,[\"isFocused\"]]],null],null],null]]],[8],[9],[0,\"\\n  \"],[1,[22,\"resolvedLabel\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"field-errors\"],[8],[1,[22,\"errorMessages\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"icon animate field-checkbox-icon \",[28,\"if\",[[24,[\"checked\"]],\"checked\"],null],\" \",[22,\"isFocused\"]]]],[8],[0,\"\\n  \"],[1,[28,\"if\",[[24,[\"checked\"]],\"check_box\",\"check_box_outline_blank\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/checkbox.hbs"
    }
  });

  _exports.default = _default;
});