define("@weareevermore/shuttle/templates/components/shuttle-person/meetings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUb3ZTxj",
    "block": "{\"symbols\":[\"meeting\"],\"statements\":[[4,\"each\",[[24,[\"_meetings\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"shuttle-preview-list-item test-contact-meeting\"],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[29,[[23,1,[\"attributes\",\"url\"]]]]],[10,\"target\",\"_blank\"],[10,\"class\",\"shuttle-preview-list-item-primary\"],[8],[0,\"\\n      \"],[1,[23,1,[\"attributes\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"shuttle-preview-list-item-secondary\"],[8],[0,\"\\n      \"],[1,[23,1,[\"attributes\",\"info\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"shuttle-preview-list-empty shuttle-preview-blur pb-2 meetings-empty\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"shuttle-preview-field-value-empty\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"_loadDataTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"        \"],[5,\"loader\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        EMPTY\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person/meetings.hbs"
    }
  });

  _exports.default = _default;
});