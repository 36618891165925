define("@weareevermore/satellite/mixins/base-input", ["exports", "@weareevermore/satellite/utils/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mixin = Ember.mixin;

  var _default = Ember.Mixin.create({
    classNameBindings: ['_isActive:is-active', 'isFocused', 'hasErrors', ':field'],
    hasErrors: Ember.computed.notEmpty('errors'),
    required: false,
    _isActive: Ember.computed('isActive', 'isFocused', 'placeholder', 'value', function () {
      return this.get('isActive') || this.get('isFocused') || !Ember.isEmpty(this.get('value')) || !Ember.isEmpty(this.get('placeholder'));
    }),
    resolvedLabel: Ember.computed('label', 'humanizedLabel', function () {
      return this.get('label') || this.get('humanizedLabel');
    }),
    humanizedLabel: Ember.computed('label', 'for', function () {
      return this.get('label') !== false && (0, _humanize.default)(this.get('for')) || '';
    }),
    errorMessages: Ember.computed('errors.[]', function () {
      if (this.get('hasErrors')) {
        return this.get('errors').mapBy('message').join(', ');
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('formModel') && this.get('for')) {
        var object = {};

        if (Ember.isNone(this.get('value'))) {
          object.value = Ember.computed.alias("formModel.".concat(this.get('for')));
        }

        if (Ember.isNone(this.get('errors'))) {
          object.errors = Ember.computed.alias("formErrors.".concat(this.get('for')));
        }

        mixin(this, object);
      }
    }
  });

  _exports.default = _default;
});