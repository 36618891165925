define("@weareevermore/shuttle/templates/components/shuttle-slot/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bdj8hSsZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-autoid\",\"slot-form\"],[8],[0,\"\\n    \"],[5,\"shuttle-select\",[],[[\"@type\",\"@label\",\"@selected\",\"@disabled\",\"@onChange\"],[[22,\"selectType\"],[22,\"selectLabel\"],[22,\"selected\"],[22,\"isDisabled\"],[28,\"action\",[[23,0,[]],\"handleChange\"],null]]]],[0,\"\\n\\n    \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isActive\"]]],null],false],null]],[10,\"class\",\"btn btn-secondary btn-sm\"],[11,\"disabled\",[22,\"isDisabled\"]],[10,\"data-autoid\",\"cancel\"],[10,\"type\",\"button\"],[8],[0,\"\\n      Cancel\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isActive\"]]],null],true],null]],[10,\"class\",\"btn btn-sm btn-secondary\"],[10,\"data-autoid\",\"slot-btn\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"icon\",[\"add\"],null],false],[0,\" Add \"],[1,[28,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"people\"],null],\"Person\",\"Institution\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-slot/form.hbs"
    }
  });

  _exports.default = _default;
});