define("@weareevermore/satellite/components/nav", ["exports", "@weareevermore/satellite/constants/apps", "@weareevermore/satellite/templates/components/nav"], function (_exports, _apps, _nav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _nav.default,
    router: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['nav', 'nav-fixed', 'flex-row'],
    logout: 'logout',
    // eslint-disable-next-line ember/no-ember-testing-in-module-scope
    _testingAvatar: Ember.testing,
    appName: 'Satellite',
    isDropdownVisible: false,
    currentURL: Ember.computed.readOnly('router.currentURL'),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    avatar: Ember.computed('user.id', function () {
      var testAvatar = '/@weareevermore/satellite/tests/pixel.png';
      var avatar = "https://auth.worldjewishcongress.org/avatar/".concat(this.user.id, "?white");
      return this._testingAvatar ? testAvatar : avatar;
    }),
    userApps: Ember.computed('user.apps', 'currentCountryISO', function () {
      var _this = this;

      var userApps = this.get('user.apps');
      var currentCountryISO = this.get('currentCountryISO');
      return _apps.default.reduce(function (acc, app) {
        if (userApps && userApps[app.key] && userApps[app.key] !== 'none') {
          var link = _this._appendRedirectToLink(app, currentCountryISO);

          app = Ember.assign({}, app, {
            link: link
          });
          acc.push(app);
        }

        return acc;
      }, []);
    }),
    crumbs: Ember.computed('currentURL', 'currentRouteName', function () {
      var crumbs = this._mapRoutes(function (_ref) {
        var path = _ref.path,
            route = _ref.route;
        var title = route ? Ember.get(route, 'title') : null;
        var routeCrumbs = route ? Ember.get(route, 'crumbs') : null;

        if (routeCrumbs) {
          return routeCrumbs;
        } else if (title) {
          return {
            route: path,
            title: title
          };
        }

        return null;
      }).filter(Ember.isPresent).flat();

      this._setPageTitle(crumbs);

      return crumbs;
    }),
    currentCountryISO: Ember.computed('currentURL', 'currentRouteName', function () {
      return this._mapRoutes(function (_ref2) {
        var route = _ref2.route;
        return Ember.get(route, 'countryISO');
      }).filter(Ember.isPresent)[0];
    }),
    _mapRoutes: function _mapRoutes(callback) {
      var container = Ember.getOwner(this);
      var routeNames = (this.currentRouteName || '').split('.');
      return routeNames.filter(Ember.isPresent).map(function (name, index) {
        var path = routeNames.slice(0, index + 1).join('.');
        var route = container.lookup("route:".concat(path));
        return callback({
          path: path,
          route: route
        });
      });
    },
    _setPageTitle: function _setPageTitle(crumbs) {
      var title = this.appName;

      if (Ember.isPresent(crumbs)) {
        var front = crumbs.map(function (crumb) {
          return crumb.title;
        }).reverse().join(' - ');
        title = "".concat(front, " :: ").concat(title);
      }

      document.title = title;
    },
    _appendRedirectToLink: function _appendRedirectToLink(app, currentCountryISO) {
      if (Ember.isEmpty(currentCountryISO)) {
        return app.link;
      }

      switch (app.key) {
        case 'security':
          return "".concat(app.link, "/open/").concat(currentCountryISO);

        case 'desks':
          return "".concat(app.link, "/countries/open/").concat(currentCountryISO);

        default:
          return app.link;
      }
    },
    actions: {
      logout: function logout() {
        this.sendAction('logout'); // eslint-disable-line ember/closure-actions
      }
    }
  });

  _exports.default = _default;
});