define("@weareevermore/satellite/templates/components/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W3S+JO0J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasMessage\"]]],null,{\"statements\":[[4,\"transition-group\",null,[[\"transitionName\",\"class\",\"id\"],[\"dialog\",\"dialog-backdrop flex-row\",\"dialog-backdrop\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"dialog\"],[10,\"id\",\"dialog\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"message\",\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"header\",true],[10,\"class\",\"dialog-header\"],[8],[1,[24,[\"message\",\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"section\",true],[10,\"class\",\"dialog-content\"],[8],[0,\"\\n        \"],[1,[24,[\"message\",\"message\"]],true],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"footer\",true],[10,\"class\",\"dialog-footer\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"displayCancelButton\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"cancel\"],null]],[10,\"class\",\"btn btn-secondary\"],[10,\"data-autoid\",\"cancel-dialog\"],[10,\"type\",\"button\"],[8],[0,\"\\n            \"],[1,[24,[\"message\",\"cancelLabel\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"confirm\"],null]],[10,\"class\",\"btn btn-primary\"],[10,\"data-autoid\",\"confirm-dialog\"],[10,\"type\",\"button\"],[8],[0,\"\\n          \"],[1,[24,[\"message\",\"confirmLabel\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/dialog.hbs"
    }
  });

  _exports.default = _default;
});