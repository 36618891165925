define("@weareevermore/satellite/components/progress", ["exports", "@weareevermore/satellite/templates/components/progress"], function (_exports, _progress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _progress.default,
    classNames: ['progress']
  });

  _exports.default = _default;
});