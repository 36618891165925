define("@weareevermore/satellite/components/satellite-promise", ["exports", "@weareevermore/satellite/templates/components/satellite-promise"], function (_exports, _satellitePromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Promise = Ember.Component.extend({
    layout: _satellitePromise.default,
    tagName: '',
    isLoading: true,
    result: null,
    promise: Ember.computed({
      set: function set(key, promise) {
        var _this = this;

        if (!promise || typeof promise.then !== 'function') {
          this._setIsLoading(false, promise);

          return promise;
        }

        this._setIsLoading(true, null);

        promise.then(function (result) {
          _this._setIsLoading(false, result);
        }).catch(function () {
          _this._setIsLoading(false, null);
        });
        return promise;
      }
    }),
    _setIsLoading: function _setIsLoading(value, result) {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.setProperties({
        isLoading: value,
        result: result
      });
    }
  });
  Promise.reopenClass({
    positionalParams: ['promise']
  });
  var _default = Promise;
  _exports.default = _default;
});