define("@weareevermore/satellite/components/radio-buttons", ["exports", "@weareevermore/satellite/mixins/base-input", "@weareevermore/satellite/templates/components/radio-buttons"], function (_exports, _baseInput, _radioButtons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseInput.default, {
    layout: _radioButtons.default,
    checked: Ember.computed.alias('value'),
    isActive: true,
    actions: {
      change: function change(value) {
        this.set('checked', value);
      }
    }
  });

  _exports.default = _default;
});