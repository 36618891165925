define("@weareevermore/shuttle/components/shuttle-institution", ["exports", "@weareevermore/shuttle/templates/components/shuttle-institution"], function (_exports, _shuttleInstitution) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _shuttleInstitution.default,
    store: Ember.inject.service('store'),
    institutionService: Ember.inject.service('shuttle-institution'),
    widget: Ember.inject.service('shuttle-widget'),
    classNameBindings: [':shuttle-preview', ':shuttle-institution', ':flex-column', 'hasFocused:shuttle-preview-has-focused'],
    currentTab: 'info',
    hasFocused: Ember.computed('currentFocus', 'newHeadquarterForm', function () {
      return this.get('currentFocus') || this.get('newHeadquarterForm');
    }),
    isAccordionDisabled: Ember.computed('currentFocus', 'hasFocused', function () {
      return this.get('hasFocused') && this.get('currentFocus') !== 'headquarter';
    }),
    listFilter: Ember.computed('institution.id', function () {
      return {
        institution: this.get('institution.id')
      };
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('institutionService').findRecord(this.get('uuid')).then(function (institution) {
        _this.set('institution', institution);
      });
      this.set('newHeadquarter', Ember.Object.create({
        contacts: []
      }));
    },
    actions: {
      saveInstitution: function saveInstitution(toggleForm, attrs) {
        var institution = this.get('institution');
        return this.get('institutionService').updateRecord(institution, attrs).then(toggleForm);
      },
      archiveInstitution: function archiveInstitution() {
        var _this2 = this;

        var institution = this.get('institution');
        return this.get('institutionService').updateRecord(institution, {
          isArchived: true
        }).then(function () {
          _this2.get('widget').temp('institutions');
        });
      },
      saveContacts: function saveContacts(data) {
        var institution = this.get('institution');
        return this.get('institutionService').updateContacts(institution, data);
      },
      createHeadquarter: function createHeadquarter(data) {
        var institution = this.get('institution');
        return this.get('institutionService').createHeadquarter(institution, data);
      },
      updateHeadquarter: function updateHeadquarter(headquarter, data) {
        return this.get('institutionService').updateHeadquarter(headquarter, data);
      },
      deleteHeadquarter: function deleteHeadquarter(headquarter) {
        var _this3 = this;

        return this.get('institutionService').deleteHeadquarter(headquarter).then(function () {
          _this3.set('currentFocus', null);
        });
      },
      setAttrAndUpdateScroll: function setAttrAndUpdateScroll(attrName, value) {
        var _this4 = this;

        this.set(attrName, value);
        Ember.run(function () {
          var event = new Event('update-scroll');

          _this4.element.querySelector('.shuttle-scrollbar').dispatchEvent(event);
        });
      }
    }
  });

  _exports.default = _default;
});