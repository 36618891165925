define("@weareevermore/shuttle/components/shuttle-person/relation-form", ["exports", "@weareevermore/shuttle/templates/components/shuttle-person/relation-form"], function (_exports, _relationForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TYPES = [{
    groupName: 'Family',
    options: ['Child', 'Grandchild', 'Grandparent', 'Parent', 'Partner', 'Relative', 'Spouse']
  }, {
    groupName: 'Professional',
    options: ['Assistant', 'Security Guard', 'Security Supervisor', 'Supervisor', 'WJC Point Person']
  }];

  var _default = Ember.Component.extend({
    layout: _relationForm.default,
    types: TYPES,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        type: null,
        relatedTo: null
      });
    },
    actions: {
      save: function save() {
        var attrs = this.getProperties('type', 'relatedTo');
        this.set('disabled', true);
        this.onSave(attrs).then(this.onCancel);
      }
    }
  });

  _exports.default = _default;
});