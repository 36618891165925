define("@weareevermore/wjc-auth/services/auth-ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    trustedHosts: [/\.worldjewishcongress\.org/],

    get headers() {
      return this.session.requestHeaders();
    }

  });

  _exports.default = _default;
});