define("@weareevermore/shuttle/templates/components/shuttle-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D8oGCQ3d",
    "block": "{\"symbols\":[\"record\"],\"statements\":[[5,\"shuttle-list/search\",[[12,\"data-autoid\",\"search\"]],[[\"@tags\",\"@term\",\"@onChange\"],[[22,\"tags\"],[22,\"term\"],[28,\"action\",[[23,0,[]],\"handleSearch\"],null]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"shuttle-list-loader\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[5,\"progress\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[5,\"scrollbar\",[[12,\"class\",\"shuttle-list-items shuttle-scrollbar flex-auto\"],[12,\"data-autoid\",\"list\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"people\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"shuttle-list/person\",[[12,\"data-autoid\",\"item\"]],[[\"@person\",\"@click\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[24,[\"onPersonClick\"]],[23,1,[\"id\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"shuttle-list/institution\",[[12,\"data-autoid\",\"item\"]],[[\"@institution\",\"@click\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[24,[\"onInstitutionClick\"]],[23,1,[\"id\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[5,\"shuttle-list/empty\",[],[[\"@tags\",\"@term\",\"@isLoading\",\"@onClear\"],[[22,\"tags\"],[22,\"term\"],[22,\"isLoading\"],[28,\"action\",[[23,0,[]],\"handleSearch\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"pagination\",[[12,\"data-autoid\",\"pagination\"]],[[\"@page\",\"@size\",\"@total\",\"@onChange\"],[[22,\"page\"],[22,\"size\"],[24,[\"data\",\"meta\",\"total\"]],[28,\"action\",[[23,0,[]],\"handlePageChange\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-list.hbs"
    }
  });

  _exports.default = _default;
});