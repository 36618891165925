define("@weareevermore/shuttle/components/shuttle-person/membership-form", ["exports", "@weareevermore/shuttle/templates/components/shuttle-person/membership-form", "@weareevermore/shuttle/statics/contact-labels"], function (_exports, _membershipForm, _contactLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _membershipForm.default,
    init: function init() {
      var _this$get;

      this._super.apply(this, arguments);

      var contacts = this.get('membership.contacts').map(function (contact) {
        return Ember.Object.create({
          id: contact.get('id'),
          attrs: contact.getProperties('value', 'label', 'isPrimary')
        });
      });
      var fields = ['institution', 'headquarter', 'position', 'department', 'inOfficeSince', 'inOfficeUntil'];
      this.set('membership', Ember.Object.create({
        id: this.get('membership.id'),
        attrs: (_this$get = this.get('membership')).getProperties.apply(_this$get, fields),
        contacts: Ember.A(contacts)
      }));
    },
    actions: {
      save: function save() {
        this.set('isSaving', true);
        this.onSave(this.get('membership')).then(this.onCancel);
      },
      chooseInstitution: function chooseInstitution(institution) {
        this.set('membership.attrs.institution', institution);
        this.set('membership.attrs.headquarter', null);
      },
      addContact: function addContact() {
        var contact = Ember.Object.create({
          attrs: {
            label: _contactLabels.default[0].value
          },
          isNew: true
        });
        this.get('membership.contacts').addObject(contact);
      },
      handleContactDelete: function handleContactDelete(contact, value) {
        if (contact.get('isNew')) {
          this.get('membership.contacts').removeObject(contact);
        } else {
          contact.set('isDeleted', value);
        }
      }
    }
  });

  _exports.default = _default;
});