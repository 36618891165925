define("@weareevermore/shuttle/templates/components/shuttle-person/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ad6t5Adw",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"person\",\"fullName\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-person/link.hbs"
    }
  });

  _exports.default = _default;
});