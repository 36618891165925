define("@weareevermore/shuttle/templates/components/shuttle-list/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3t4X1gZc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-autoid\",\"empty-message\"],[8],[0,\"Hold your horses. The records are being fetched.\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"hasQuery\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-autoid\",\"empty-message\"],[8],[0,\"There are no records matching the search query.\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"term\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"spacer-top-small spacer-bottom-small\"],[8],[0,\"Term: \"],[7,\"em\",true],[10,\"data-autoid\",\"current-term\"],[8],[1,[22,\"term\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"tags\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"spacer-top-small spacer-bottom-small\"],[8],[0,\"Tags: \"],[7,\"em\",true],[10,\"data-autoid\",\"current-tags\"],[8],[1,[22,\"currentTags\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onClear\"]]],null]],[10,\"class\",\"btn btn-secondary\"],[10,\"data-autoid\",\"clear-search\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"icon\",[\"clear\"],null],false],[0,\" Clear Search\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"data-autoid\",\"empty-message\"],[8],[0,\"Looks like there are no records.\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-list/empty.hbs"
    }
  });

  _exports.default = _default;
});