define("@weareevermore/shuttle/components/shuttle-preview/contact", ["exports", "@weareevermore/shuttle/templates/components/shuttle-preview/contact"], function (_exports, _contact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ICON_MAP = {
    'mobile/cell': 'mobile',
    'web site': 'website'
  };
  var PREFIX_MAP = {
    'e-mail': 'mailto:',
    facebook: 'https://facebook.com/',
    fax: 'tel:',
    im: '',
    'mobile/cell': 'tel:',
    phone: 'tel:',
    skype: 'skype:',
    twitter: 'https://twitter.com/',
    youtube: 'https://youtube.com/'
  };

  var _default = Ember.Component.extend({
    layout: _contact.default,
    classNames: ['shuttle-preview-contact', 'flex-row', 'flex-center'],
    label: Ember.computed('contact.label', function () {
      return (this.get('contact.label') || '').toLowerCase();
    }),
    icon: Ember.computed('label', function () {
      return ICON_MAP[this.get('label')] || this.get('label');
    }),
    href: Ember.computed('label', 'contact.value', function () {
      var value = this.get('contact.value');
      var label = this.get('label');

      if (label === 'web site') {
        value = value.match(/^https?:\/\//) ? value : 'http://' + value;
      }

      return (PREFIX_MAP[label] || '') + value;
    })
  });

  _exports.default = _default;
});