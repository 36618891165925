define("@weareevermore/satellite/components/error-page", ["exports", "@weareevermore/satellite/templates/components/error-page"], function (_exports, _errorPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _errorPage.default,
    classNames: ['error-page'],
    status: Ember.computed('error.status', function () {
      return (this.get('error.status') || '').toString();
    }),
    is404: Ember.computed('status', function () {
      return this.status === '404';
    }),
    is400: Ember.computed('status', function () {
      return this.status.indexOf('4') === 0;
    }),
    is500: Ember.computed('status', function () {
      return this.status.indexOf('5') === 0;
    })
  });

  _exports.default = _default;
});