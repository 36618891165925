define("@weareevermore/shuttle/templates/components/shuttle-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6cvLmpt1",
    "block": "{\"symbols\":[\"Select\",\"record\"],\"statements\":[[5,\"power-select-field\",[[12,\"placeholder\",[22,\"placeholder\"]],[12,\"data-autoid\",\"shuttle-select\"]],[[\"@label\",\"@selected\",\"@errors\",\"@isFocused\"],[[22,\"label\"],[22,\"selected\"],[22,\"errors\"],[22,\"isFocused\"]]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"component\",[[23,0,[\"componentName\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[12,\"}}\",\"\"]],[[\"@allowClear\",\"@disabled\",\"@dropdownClass\",\"@noMatchesMessage\",\"@onBlur\",\"@onChange\",\"@onCreate\",\"@onFocus\",\"@placeholder\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@searchMessage\",\"@selectClass\",\"@selected\",\"@selectedItemComponent\",\"@suggestedOptionComponent\",\"@triggerClass\"],[[22,\"allowClear\"],[22,\"_disabled\"],[22,\"dropdownClass\"],\"There are no records matching the search query\",[28,\"perform\",[[24,[\"_handleFocusTask\"]],false],null],[28,\"action\",[[23,0,[]],[24,[\"onChange\"]]],null],[28,\"perform\",[[24,[\"_createTask\"]]],null],[28,\"perform\",[[24,[\"_handleFocusTask\"]],true],null],[22,\"placeholder\"],[28,\"action\",[[23,0,[]],[24,[\"search\"]]],null],true,\"selectTitle\",\"Search records from the database\",[22,\"selectClass\"],[22,\"selected\"],\"shuttle-select/selected\",[28,\"concat\",[\"shuttle-select/\",[24,[\"createComponentName\"]]],null],[22,\"triggerClass\"]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"shuttle-select-item-title\"],[8],[1,[23,2,[\"selectTitle\"]],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"shuttle-select-item-subtitle\"],[8],[1,[23,2,[\"selectSubtitle\"]],false],[9],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-select.hbs"
    }
  });

  _exports.default = _default;
});