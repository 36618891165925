define("@weareevermore/shuttle/statics/contact-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.A([{
    value: 'e-mail',
    label: 'Email'
  }, {
    value: 'Facebook',
    label: 'Facebook'
  }, {
    value: 'FAX',
    label: 'FAX'
  }, {
    value: 'IM',
    label: 'IM'
  }, {
    value: 'Mobile/cell',
    label: 'Mobile/cell'
  }, {
    value: 'Phone',
    label: 'Phone'
  }, {
    value: 'Skype',
    label: 'Skype'
  }, {
    value: 'Twitter',
    label: 'Twitter'
  }, {
    value: 'Web Site',
    label: 'Web Site'
  }, {
    value: 'YouTube',
    label: 'YouTube'
  }]);

  _exports.default = _default;
});