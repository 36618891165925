define("@weareevermore/satellite/components/fieldset", ["exports", "@weareevermore/satellite/templates/components/fieldset"], function (_exports, _fieldset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _fieldset.default,
    tagName: 'fieldset',
    classNames: ['fieldset']
  });

  _exports.default = _default;
});