define("@weareevermore/wjc-auth/controllers/auth-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    queryParams: ['token', 'to']
  });

  _exports.default = _default;
});