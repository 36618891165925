define("@weareevermore/satellite/templates/components/empty-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fUmiOs+S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"icon\",[[24,[\"icon\"]]],[[\"class\"],[\"empty-state-icon\"]]],false],[0,\"\\n\"],[7,\"h4\",true],[10,\"class\",\"empty-state-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"info\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"empty-state-info\"],[8],[1,[22,\"info\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/satellite/templates/components/empty-state.hbs"
    }
  });

  _exports.default = _default;
});