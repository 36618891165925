define("@weareevermore/satellite/helpers/are-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.areEmpty = areEmpty;
  _exports.default = void 0;

  function areEmpty(params) {
    for (var i = 0; i < params.length; i++) {
      if (Ember.isEmpty(params[i])) {
        return true;
      }
    }

    return false;
  }

  var _default = Ember.Helper.helper(areEmpty);

  _exports.default = _default;
});