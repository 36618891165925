define("@weareevermore/shuttle/components/shuttle-person/relation", ["exports", "@weareevermore/shuttle/templates/components/shuttle-person/relation"], function (_exports, _relation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _relation.default,
    classNames: ['shuttle-preview-list-item', 'flex-row', 'flex-center'],
    // Actions
    onDelete: Ember.RSVP.resolve
  });

  _exports.default = _default;
});