define("@weareevermore/satellite/components/error-page/footer", ["exports", "@weareevermore/satellite/templates/components/error-page/footer"], function (_exports, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _footer.default,
    classNames: ['error-page-footer']
  });

  _exports.default = _default;
});