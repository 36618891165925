define("@weareevermore/shuttle/components/shuttle-slot/item", ["exports", "@weareevermore/shuttle/templates/components/shuttle-slot/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _item.default,
    classNames: ['shuttle-slot-item', 'animate', 'flex-row', 'flex-center']
  });

  _exports.default = _default;
});