define("@weareevermore/shuttle/templates/components/shuttle-slot/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XteNyfPc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"shuttle-slot-header-main flex-auto\"],[10,\"data-autoid\",\"slot-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[1,[28,\"icon\",[\"keyboard_arrow_down\"],[[\"class\"],[\"shuttle-slot-header-icon animate\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@weareevermore/shuttle/templates/components/shuttle-slot/header.hbs"
    }
  });

  _exports.default = _default;
});