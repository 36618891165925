define("@weareevermore/shuttle/models/shuttle-institution", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Attributes
    name: (0, _attr.default)('string'),
    officialName: (0, _attr.default)('string'),
    abbreviation: (0, _attr.default)('string'),
    deskOfficer: (0, _attr.default)('string'),
    isInternationalOrganisation: (0, _attr.default)('boolean'),
    isArchived: (0, _attr.default)('boolean'),
    // Relations
    contacts: (0, _relationships.hasMany)('shuttle-contact'),
    headquarters: (0, _relationships.hasMany)('shuttle-headquarter'),
    primaryHeadquarter: (0, _relationships.belongsTo)('shuttle-headquarter'),
    pointPerson: (0, _relationships.belongsTo)('shuttle-person'),
    tags: (0, _relationships.hasMany)('shuttle-tag'),
    // Computed
    selectTitle: Ember.computed.readOnly('name'),
    selectSubtitle: Ember.computed.readOnly('officialName')
  });

  _exports.default = _default;
});